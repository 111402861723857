.records-index .records-container .records-head {
  position: sticky;
  top: 0;
}
.records-index .records-container .records-head .adm-tabs-header {
  border-bottom: none;
}
.records-index .records-container .records-head .adm-tabs-tab-wrapper {
  padding: 0;
}
.records-index .records-container .records-head .adm-tabs-tab-wrapper .adm-tabs-tab {
  color: #666;
}
.records-index .records-container .records-head .adm-tabs-tab-wrapper .adm-tabs-tab-active {
  font-weight: 500;
  font-size: 17px;
}
.records-index .records-container .records-body {
  background-color: #F5F6F8;
}
.records-index .records-container .records-body .records-list {
  background-color: #F5F6F8;
  border: 0.5px solid white;
  height: calc(100vh - 40px - 40px - env(safe-area-inset-top) - 10px);
  overflow: scroll;
  margin-bottom: 10px;
}
.records-index .records-container .records-body .records-list .records-item {
  margin: 10px 15px 0;
  padding: 11px;
  background-color: white;
  border-radius: 13px;
  display: flex;
  align-items: center;
}
.records-index .records-container .records-body .records-list .records-item img {
  height: 60px;
}
.records-index .records-container .records-body .records-list .records-item .records-item-content {
  padding-left: 5px;
  flex: 1;
}
.records-index .records-container .records-body .records-list .records-item .records-item-content .title {
  color: #FE4A50;
  font-size: 10px;
}
.records-index .records-container .records-body .records-list .records-item .records-item-content .gamename {
  color: #282828;
  font-size: 14px;
}
.records-index .records-container .records-body .records-list .records-item .records-item-content .time {
  color: #999;
  font-size: 10px;
}
.records-index .records-container .records-body .records-list .records-item .action {
  background-color: #FAC406;
  color: white;
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 17px;
}
.records-index .records-container .records-body .records-list .records-item .action.disable {
  background-color: #F3F4F8;
  color: #999;
}
.records-index .records-container .records-body .records-list .records-gift-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 15px 0;
  padding: 11px;
  background-color: white;
  border-radius: 13px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-container {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #DEDEDE;
  padding-bottom: 10px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-container img {
  height: 60px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-container .records-item-content {
  padding-left: 5px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-container .records-item-content .gamename {
  color: #282828;
  font-size: 14px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-container .records-item-content .time {
  color: #999;
  font-size: 10px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-action {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-action .gift-name {
  color: #999;
  font-size: 12px;
}
.records-index .records-container .records-body .records-list .records-gift-item .gift-action .gift-copy {
  color: #FE4A50;
  font-size: 12px;
}
.records-index .records-container .records-body .empty {
  padding: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winning-container .head {
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  color: #282828;
  padding: 6px 0;
}
.winning-container .list {
  padding: 0 15px;
}
.winning-container .list .item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  color: #282828;
  padding: 13px 0;
  border-bottom: 0.5px solid #DEDEDE;
}
.winning-container .empty {
  padding: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
