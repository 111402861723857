.recommend-index {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  

  &-content {
    
    .close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 15px;
      font-size: 13px;
      color: #999;
    }

    .title {
      color: #282828;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-top: 40px;
    }

    .container {
      padding-top: 25px;
      padding-left: 25px;
      padding-right: 25px;
      column-count: 4;

      .item {
        text-align: center;
        margin-bottom: 15px;
        background-color: #F7F7F7;
        font-size: 14px;
        color: #282828;
        border-radius: 16px;
        padding: 5px 10px;
        border: 1px solid transparent;

        &.active {
          color: var(--MainColor);
          border: 1px solid var(--MainColor);
          background-color: white;
        }
      }

      
    }

    .done {
      color: white;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding: 10px;
      border-radius: 25px;
      margin: 40px 25px 0;
      // background-color: #999;
      background-color: #EDEDED;

      &.active {
        background-color: var(--MainColor);
      }
    }

    .desc {
      color: #666;
      font-size: 10px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: calc(env(safe-area-inset-bottom) + 10px);
    }
  }
}