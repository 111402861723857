.game-tools-index {
  min-height: calc(100vh - 120px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  .game-tools-index-container {

    .game-tools-list-item {
       background-color: white;
       margin-bottom: 10px;
       border-radius: 8px;
       overflow: hidden;

       .item-top {
        display: flex;
        align-items: center;
        margin: 20px;

          .icon {
            height: 27px;
            width: 27px;
          }
          
          .content {
            flex: 1;
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {

              .title {
                color: #282828;
                font-size: 15px;
              }
  
              .desc {
                color: #999;
                font-size: 12px;
              }
            }

            .right {
              border: 1px solid #FFC000;
              border-radius: 13px;
              padding: 3px 9px;
              color: #FFC000;
              font-size: 11px;
            }
          }
       }

       .item-line {
        background-color: #EEE;
        height: 0.5px;
       }

       .item-content {
        font-size: 12px;
        color: #999;
        padding: 0 15px;
       }

       .item-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;

        .text {
          font-size: 10px;
          color: #999;
        }

        .icon {
          height: 8px;
          margin-left: 8px;
        }

       }
    }
  }
}