.community-detail .container {
  background-color: #F5F6F8;
  min-height: calc(100vh - 40px - env(safe-area-inset-top));
}
.community-detail .container .detail-header {
  background-color: white;
  padding: 0 15px 25px 15px;
}
.community-detail .container .detail-header .type img {
  height: 24px;
  margin-top: 10px;
  vertical-align: middle;
}
.community-detail .container .detail-header .title {
  margin-top: 10px;
  font-size: 0.48rem;
  color: #282828;
  font-weight: 600;
}
.community-detail .container .detail-header .userinfo {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.community-detail .container .detail-header .userinfo .avatar {
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
.community-detail .container .detail-header .userinfo .content {
  margin-left: 12px;
}
.community-detail .container .detail-header .userinfo .content .username {
  font-size: 0.26rem;
  color: #282828;
}
.community-detail .container .detail-header .userinfo .content .time {
  font-size: 0.2rem;
  color: #999;
  margin-top: 4px;
}
.community-detail .container .detail-header .contents {
  padding-top: 15px;
  font-size: 0.28rem;
  color: #666;
}
.community-detail .container .detail-header .contents p {
  margin-top: 10px;
}
.community-detail .container .detail-header .contents p img {
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}
.community-detail .container .detail-header .video {
  width: 92%;
  height: calc((99vw - 30px) * 9 / 16);
  margin-left: 4%;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  display: flex;
}
.community-detail .container .detail-header .video video {
  border-radius: 10px;
  overflow: hidden;
}
.community-detail .container .detail-header .video .video-react-control-bar {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.community-detail .container .detail-header .video .video-react.video-react-fluid,
.community-detail .container .detail-header .video .video-react.video-react-16-9,
.community-detail .container .detail-header .video .video-react.video-react-4-3 {
  height: 100%;
}
.community-detail .container .detail-header .video .video-react-controls-enabled {
  padding-top: 0 !important;
}
.community-detail .container .detail-header .imgs {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}
.community-detail .container .detail-header .imgs .img {
  flex-basis: 30vw;
  height: 30vw;
  padding-right: 10px;
  padding-top: 10px;
}
.community-detail .container .detail-header .imgs .img img {
  object-fit: cover;
  border-radius: 10px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.community-detail .container .detail-header .wap .image {
  width: 225px;
  height: 127px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 20px;
  vertical-align: middle;
}
.community-detail .container .comments {
  padding: 0 15px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
}
.community-detail .container .comments .toolbar {
  padding-top: 30px;
  padding-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.community-detail .container .comments .toolbar .number {
  font-size: 0.32rem;
  color: #282828;
  font-weight: 500;
}
.community-detail .container .comments .toolbar .sort span {
  color: #999;
  font-size: 0.24rem;
}
.community-detail .container .comments .toolbar .sort img {
  width: 11px;
  height: 11px;
  vertical-align: middle;
  margin-left: 2px;
}
.community-detail .container .comments .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  color: #666;
  padding: 50px 0;
}
.community-detail .container .comments .empty img {
  display: block;
  width: 50%;
}
.community-detail .container .comment-reply {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.community-detail .container .comment-reply .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px calc(env(safe-area-inset-bottom) + 5px) 15px;
}
.community-detail .container .comment-reply .input input {
  padding: 12px 20px;
  border-radius: 20px;
  border: none;
  background-color: #F5F6F8;
  flex-grow: 1;
  font-size: 14px;
  color: #282828;
}
.community-detail .container .comment-reply .input .send {
  padding: 10px 0 10px 10px;
}
.community-detail .container .comment-reply .input .like {
  font-size: 0.22rem;
  color: #999;
  text-align: center;
  padding-left: 15px;
}
.community-detail .container .comment-reply .input .like img {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}
