.announcement-index {
  padding-top: 15px;
  background-color: #F5F6F8;
  height: calc(100vh - env(safe-area-inset-top) - 44px);

  .item {
    background-color: white;
    margin: 0 15px 10px;
    padding: 0 15px;
    border-radius: 8px;

    .header {
      display: flex;
      align-items: center;
      padding-top: 20px;
      position: relative;

      .tag {
        padding: 3px 4px;
        border-radius: 2px;
        font-size: .2rem;
        color: white;
        background-color: #F70454;
        margin-right: 6px;
      }

      .title {
        font-size: .32rem;
        color: #FFC000;
        font-weight: 500;
      }

      .point {
        width: 7px;
        height: 7px;
        border-radius: 3.5px;
        position: absolute;
        right: 0px;
        top: 15px;
        background-color: red;
      }
    }

    .content {
      color: #282828;
      font-size: .26rem;
      padding-top: 10px;
      max-height: 80px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }

    .time {
      text-align: end;
      font-size: .26rem;
      color: #282828;
      padding: 25px 0;
    }
  }
}

.announcement-alert {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .announcement-mask {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    transition: all .25s;
    opacity: 0;
  }

  .announcement-container {
    position: relative;
    z-index: 666;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: env(safe-area-inset-bottom);
    transition: all .25s;
    opacity: 0;
    transform: translateY(100%);
  }

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done {
    .announcement-mask {
      opacity: 1;
    }

    .announcement-container {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.exit-active {
    .announcement-mask {
      opacity: 0;
    }

    .announcement-container {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  &.exit-done {
    display: none;
  }

  .announcement-top {
    display: flex;
    position: relative;

    .close {
      position: absolute;
      padding: 15px;
      right: 0;
      top: 0;
    }
    
  }

  .announcement-main {
    padding: 20px 15px;

    .title {
      padding-top: 20px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: .36rem;
      color: #FFC000;
    }

    .desc {
      padding-top: 20px;
      font-size: .26rem;
      color: #282828;
    }

    .time {
      text-align: end;
      font-size: .26rem;
      color: #282828;
      padding-top: 20px;
    }
  }

  
}