.subtitle {
    border-radius: 3px;
    color: #999;
    font-size: 0.24rem;
    text-align: center;
    padding: 0.5px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 0;
    transform: translateY(-1px);
    margin-left: 2px;
}

.gfyx-item {
    background-color: white;
    border-radius: 13px;
    margin-bottom: 10px;
    padding: 13px 10px;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
            overflow: hidden;

            .game-name {
                display: flex;
                align-items: center;

                .name {
                    font-size: 15px;
                    color: #282828;
                    font-weight: 600;
                }
                .nameRemark {
                    background-color: #F6F6F6;
                    border-radius: 3px;
                    font-size: 11px;
                    color: #999999;
                    margin-left: 10px;
                    padding: 0px 3px;
                    text-align: center;
                }
            }

            .text {
                font-size: 12px;
                color: #666;
                padding-top: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
        }

        .price {
            color: #FF5E00;
            font-size: 16px;
            font-family: 500;
            &::before{
                content: '￥';
                font-size: 11px;
            }
        }
    }

    .imgs {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 65px;
        overflow: hidden;
        margin: 10px 0;

        .img {
            flex-basis: calc(calc(100% - 20px) / 3);
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            flex-shrink:0;

            .adm-image {
                height: 100%;
                .adm-image-img {
                    object-fit: cover !important;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:nth-child(2),
            &:nth-child(3){
                margin-left: 10px;
            }
        }
        
    }

    .adm-mask {
        z-index: 9999999 !important;
        background-color: black !important;
    }

    .adm-mask .adm-image-viewer-indicator {
        top: calc(env(safe-area-inset-top)) !important;
    }

    .time {
        font-size: 12px;
        color: #999;
    }
}

.czjl-item {
    background-color: white;
    border-radius: 13px;
    margin-bottom: 10px;
    padding: 15px 10px 10px;

   .content {
     display: flex;
     align-items: center;
     justify-content: flex-start;

     .icon {
        width: 107px;
        height: 68px;
        border-radius: 5px;
        object-fit: cover;

        .adm-image-img {
            object-fit: cover !important;
        }
     }

     .info {
        padding-left: 10px;

       .game-name {
         display: flex;
         align-items: center;

         .name {
           font-size: 15px;
           color: #282828;
           font-weight: 600;
           overflow: hidden;
           text-overflow: ellipsis;
           white-space: nowrap;
         }

         .nameRemark {
           background-color: #F6F6F6;
           border-radius: 3px;
           font-size: 11px;
           color: #999999;
           margin-left: 10px;
           padding: 0px 3px;
           text-align: center;
           flex-shrink: 0;
         }
       }

       .text {
         font-size: 12px;
         color: #666;
         padding-top: 2px;
       }
     }
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;

        .service {
            color: #999;
            font-size: 12px;
        }

       .price {
            color: #FF5E00;
            font-size: 16px;
            font-weight: 500;

            &::before{
                content: '￥';
                font-size: 11px;
            }
       }
    }
}