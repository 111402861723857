.community .community-container {
  background-color: #F5F6F8;
}
.community .community-container .search {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 15px;
}
.community .community-container .search .searchbar {
  background-color: white;
  height: 38px;
  border-radius: 19px;
  flex: 1;
  overflow: hidden;
}
.community .community-container .search .searchbar img {
  width: 15px;
  height: 15px;
  margin-left: 12px;
  margin-right: 6px;
  vertical-align: middle;
}
.community .community-container .search .searchbar input {
  height: 100%;
  border: none;
  font-size: 0.26rem;
}
.community .community-container .search .searchbtn {
  padding-left: 10px;
  font-size: 0.3rem;
  color: #666;
}
.community .community-container .type {
  display: flex;
  align-items: center;
  padding: 10px 15px 5px 15px;
}
.community .community-container .type .index {
  display: flex;
  overflow: scroll;
}
.community .community-container .type .index::-webkit-scrollbar {
  display: none;
}
.community .community-container .type .index-container {
  flex-shrink: 0;
}
.community .community-container .type .index-container .name {
  background-color: white;
  font-size: 0.26rem;
  padding: 3px 11px;
  margin-right: 7.5px;
  border-radius: 15px;
}
.community .community-container .type .index-container .active {
  color: #FFC000;
  border: 0.5px solid #FFC000;
}
.community .community-container .type .sort {
  font-size: 0.24rem;
  color: #999;
  flex-shrink: 0;
  padding: 5px 10px;
  background-color: transparent;
  box-shadow: -10px 0 10px #F5F6F8;
  z-index: 10;
}
.community .community-container .type .sort img {
  vertical-align: middle;
  width: 11px;
  height: 11px;
  margin-left: 1px;
}
.community .community-container .type .sort span {
  vertical-align: middle;
}
.community .community-container .community-main {
  padding-bottom: 15px;
}
.community .community-container .community-main-item {
  display: none;
  min-height: calc(100vh - 340px - env(safe-area-inset-bottom));
}
.community .community-container .community-main-item .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 40%;
}
.community .community-container .community-main-item .empty img {
  display: block;
  width: 50%;
}
.community .community-container .community-main-item.active {
  display: block;
}
.community .community-container .publish {
  position: fixed;
  bottom: 2.6rem;
  right: 15px;
  width: 44px;
}
.community .community-container .publish-alert {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.community .community-container .publish-alert .publish-mask {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.25s;
}
.community .community-container .publish-alert .publish-container {
  position: relative;
  z-index: 666;
  background-color: #F5F6F8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 250px;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.25s;
  opacity: 0;
  transform: translateY(100%);
}
.community .community-container .publish-alert .publish-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}
.community .community-container .publish-alert .publish-container .header .rule {
  color: #999;
  font-size: 0.2rem;
}
.community .community-container .publish-alert .publish-container .header .rule img {
  width: 10px;
  height: 10px;
  vertical-align: middle;
}
.community .community-container .publish-alert .publish-container .header .close {
  padding: 15px;
}
.community .community-container .publish-alert .publish-container .header .close img {
  width: 11px;
}
.community .community-container .publish-alert .publish-container .content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.community .community-container .publish-alert .publish-container .content .item {
  position: relative;
}
.community .community-container .publish-alert .publish-container .content .item .icon {
  width: 50px;
  height: 50px;
}
.community .community-container .publish-alert .publish-container .content .item .logo {
  position: absolute;
  top: 0;
  right: -13px;
  height: 13px;
}
.community .community-container .publish-alert .publish-container .content .item div {
  text-align: center;
  font-size: 0.26rem;
  color: #666;
  padding-top: 5px;
}
.community .community-container .publish-alert .publish-container .cancel {
  background-color: white;
  font-size: 0.34rem;
  padding: 10.5px;
  margin: 0 35px;
  margin-top: 28px;
  border-radius: 24.5px;
  text-align: center;
  color: #999;
}
.community .community-container .publish-alert.enter-done .publish-container {
  opacity: 1;
  transform: translateY(0);
}
