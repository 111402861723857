.game-sign {
  height: 100vh;
  .icon-container {
    position: relative;
    .icon {
      background-image: linear-gradient(rgba(0, 0, 0, 0.75),  transparent);
      left: 0;
      right: 0;
      height: calc(env(safe-area-inset-top) + 100px);
      position: absolute;
      top: 0;
    }
  }
  

  .game-sign-container {
    padding: 0 15px 25px;
    background-color: #FAFAFA;

    .style {
      padding: 0 15px;
      background-color: white;
      border-radius: 13px;
      margin-bottom: 15px;
    }

    .header {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      margin-top: -35px;
      position: relative;

      .content {
        padding-left: 10px;
        flex-grow: 1;

        .name {
          font-size: 16px;
          font-weight: 500;
          color: #282828;
        }

        .desc {
          font-size: 11px;
          color: #999;
        }
      }

      .download {
       background-color: var(--MainColor);
       color: white;
       font-size: 14;
       padding: 3px 16px;
       border-radius: 14px; 
      }
      
    }

    .date {
      overflow: hidden;
      .date-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        .record {
          color: #999;
          font-size: 11px;
        }
      }

      .line {
        background-color: #FFE6DF;
        height: 7px;
        position: relative;
        top: 23px;
        margin: 0 30px;
      }

      .sign {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .item{
          display: flex;
          flex-direction: column;
          align-items: center;

          .imgs {
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 19px;
            position: relative;            
  
            .back-img {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }
  
            &.active {
              background-color: #66666688;
              border: 2px solid var(--MainColor);
            }
  
            
          }

          .day {
            background-color: #EEEEEE;
            font-size: 10px;
            color: #999;
            width: 34px;
            height: 16px;
            border-radius: 8px;
            text-align: center;
            margin-top: 6px;

            &.active {
              background-color: var(--MainColor);
              color: white;
            }
          }
        }
        
      }

      .sign-btn {
        background-color: var(--MainColor);
        color: white;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 16px;
        border-radius: 22px;
        margin: 25px 28px 20px;
        text-align: center;
        display: block;

        &.disable {
          background-color: #ccc;
        }
      }
    }

    .list {
      background-color: transparent;

      .item {
        margin-bottom: 15px;
        background-color: white;
        display: flex;
        align-items: center;
        padding: 15px 10px;

        .content {
          padding-left: 10px;
          padding-right: 10px;
          flex-grow: 1;

          .key {
            font-size: 15px;
            color: #282828;
          }

          .value {
            font-size: 11px;
            color: #999;
          }
        }

        .day {
          background-color: #E4E4E4;
          width: 60px;
          height: 28px;
          border-radius: 14px;
          text-align: center;
          font-size: 13px;
          color: white;
          line-height: 28px;
          flex-shrink: 0;

          &.copy {
            background-color: #FFF2CC;
            color: var(--MainColor);
          }
        }
      }
    }

    .desc {
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #282828;
        padding-top: 20px;
        text-align: center;
      }

      .text {
        font-size: 13px;
        color: #666;
        padding: 15px 0 20px;
      }
    }
  }
}

.sign-alert-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container {
    background-color: white;
    border-radius: 13px;
    width: 75%;

    .head {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      .ic {
        width: 60px;
      }
    }
    

    .title {
      font-size: 17px;
      font-weight: 500;
      color: #282828;
      text-align: center;
      padding-bottom: 10px;
    }

    .gift {
      font-size: 12px;
      color: #282828;
      padding: 5px 20px 0;
    }

    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 0px;

      .value {
        font-size: 11px;
        color: #999;
      }

      .copy {
        font-size: 10px;
        color: var(--MainColor);
      }
    }

    .open {
      background-color: var(--MainColor);
      color: white;
      font-size: 15px;
      font-weight: 500;
      padding: 10px 16px;
      border-radius: 22px;
      margin: 25px 28px 20px;
      text-align: center;
    }
  }
  .close {
    width: 22px;
    padding-top: 10px;
  }
}

