.team-setting {
  .team-setting-container {

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 0;

      .title {
        font-size: .3rem;
        color: #282828;
      }

      .desc {
        font-size: .28rem;
        color: #999;
      }

      .right-icon {
        color: #999;
      }
    }

    .team-setting-header {
      padding: 0 15px 10px;

      .info {
        display: flex;
        align-items: center;
        padding: 30px 0 17.5px;

        .avatar {
          width: 63px;
          height: 63px;
          border-radius: 31.5px;
        }
  
        .tname {
          font-size: .38rem;
          color: #282828;
          font-weight: 500;
          margin-left: 15px;
        }
      }

    }
    
    .set {
      padding: 10px 15px 10px;
      border-top: 10px solid #F1F1F1;
      border-bottom: 10px solid #F1F1F1;
    }

    .route {
      padding: 15px 15px 15px;
    }

    .exit {
      border-radius: 25px;
      position: fixed;
      left: 15px;
      right: 15px;
      text-align: center;
      color: white;
      font-size: .36rem;
      font-weight: 500;
      bottom: calc(27px + env(safe-area-inset-bottom));
    }
  }

  .team-setting-alert {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  
    .team-setting-alert-mask {
      position: fixed;
      z-index: 555;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      transition: all .25s;
      opacity: 0;
    }
  
    .team-setting-alert-container {
      position: relative;
      z-index: 666;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      padding-bottom: env(safe-area-inset-bottom);
      transition: all .25s;
      opacity: 0;
      transform: translateY(100%);
      max-height: 75vh;
    }
  
    &.appear-active,
    &.appear-done,
    &.enter-active,
    &.enter-done {
      .team-setting-alert-mask {
        opacity: 1;
      }
  
      .team-setting-alert-container {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    &.exit-active {
      .team-setting-alert-mask {
        opacity: 0;
      }
  
      .team-setting-alert-container {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  
    &.exit-done {
      display: none;
    }
  
    .top {
      position: relative;

      .title {
        font-size: .36rem;
        font-weight: 500;
        color: #282828;
        text-align: center;
        padding: 20px;
      }
  
      .close {
        position: absolute;
        padding: 15px;
        right: 0;
        top: 10px;
      }
      
    }
  
    .main {
      padding: 0px 15px;
      overflow: scroll;

      .list .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .title {
          font-size: .3rem;
          columns: #282828;
        }

        img {
          width: 17px;
          height: 17px;
        }
      }

      .content {
        font-size: .26rem;
        color: #282828;
      }
  
    }

    .bottom {
      margin: 33px 15px;
      background-color: #ffc000;
      border-radius: 25px;
      height: 50px;
      line-height: 50px;
      color: white;
      font-size: .36rem;
      text-align: center;
      font-weight: 500;
    }
    
  }
}