.team-members-index {
  .team-members-container {
    position: relative;

    .search {
      background-color: white;
      padding-bottom: 20px;

      .searchbar {
        display: flex;
        align-items: center;
        background-color: #EFEFEF;
        border-radius: 4px;
        margin: 10px 15px 0;
        padding: 8px 10px;

        .search-icon {
          color: #666;
          margin-right: 5px;
        }
      }
    }

    .list {
      .item {
        .tag {
          background-color: #EEEEEE;
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          font-size: .26rem;
          color: #666;
        }
        
        .member {
          padding: 0 10px 0 15px;
          display: flex;
          align-items: center;

          .name {
            font-size: .32rem;
            color: #282828;
            font-weight: 500;
            flex: 1;
            border-bottom: 0.5px solid #eee;
            height: 65px;
            line-height: 65px;
            margin-left: 10px;
          }
        }
      }
    }

    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 20px;
      right: 0;
      color: #666;
      font-size: .22rem;
      font-weight: 500;
      top: calc(env(safe-area-inset-top) + 44px + 64px);
      bottom: 0;
    }
  }
}