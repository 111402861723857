.hangup-question-index {
  background-color: #F6F6F6;
  min-height: calc(100vh - 40px);

  .hangup-question-container {
    margin: 0 15px;
    overflow: hidden;

    .service {
      background-color: #FFC000;
      border-radius: 25px;
      padding: 12px 0;
      color: white;
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }

    .adm-list-body {
      background-color: transparent;

      .adm-list-item-content {
        border-top: none;
      }
    }

    .adm-collapse-panel-content {
      .hangup-question-item-content {
        .icon {
          width: 23px;
          margin: 0 5px 0 5px;
        }

        .title {
          font-size: 13px;
          color: #666;
          padding-left: 5px;
        }
      }
    }

    .adm-list-body-inner {

      .adm-list-item-content-arrow {
        font-size: 12px;
      }

      .adm-collapse-panel-content {
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
      }

      .item-border {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .item-border-none {
        border-radius: 13px;
      }

      .hangup-question-item {
        padding: 0;
        overflow: hidden;
        margin-top: 10px;
        background-color: white;

        .hangup-question-item-header {
          display: flex;
          align-items: center;

          .icon {
            width: 23px;
            margin: 0 5px 0 15px;
          }

          .title {
            font-size: 15px;
            color: #282828;
            font-weight: 500;
          }
        }

      }
      
    }
  }
}