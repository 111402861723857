.community-item {
  .community-item-container {
    background-color: white;
    margin-top: 7px;
    padding: 12px;
    padding-bottom: 0;

    .header {
      display: flex;
      position: relative;
      align-items: center;

      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      .username {
        font-size: .26rem;
        color: #282828;
        display: flex;
        align-items: center;

        .name-remark {
          background-color: #F6F6F6;
          border-radius: 3px;
          color: #999;
          font-size: .2rem;
          padding: 0 3px;
          margin-left: 5px;
          display: inline-block;
        }
      }

      .time {
        font-size: .2rem;
        color: #999;
        margin-top: 5px;
      }

      .gold {
        position: absolute;
        top: 0;
        right: 0;
        width: 51px;

        .number {
          position: relative;
          top: -18px;
          left: 7px;
          font-size: .14rem;
          color: #8B4BEF;
          font-weight: 600;
        }
      }
    }

    .title {
      font-size: .32rem;
      font-weight: 600;
      color: #282828;
      margin-top: 11px;
    }

    .content {
      margin-top: 11px;
      color: #666;
      font-size: .28rem;
      line-height: 1.7;
      word-wrap: break-word;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 1.34rem;
      
      a {
        color: #999;
        display: true;
        pointer-events: none;
      }
    }

    .allcontent {
      -webkit-line-clamp: revert;
      max-height: fit-content;
    }

    .video {
      width: 225px;
      height: 126px;
      overflow: hidden;
      margin-top: 15px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      video {
        border-radius: 10px;
      }

      .video-react-poster {
        object-fit: fill;
      }
      
      .video-react-control-bar {
        visibility: hidden;
      }

      .video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
        height: 100%;
      }
      
      .video-react-controls-enabled{
        padding-top: 0 !important;
      }
      
      .playbtn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
        }
      }
    }

    .wap {
      .image {
        width: 225px;
        height: 127px;
        object-fit: cover;
        border-radius: 10px;
        margin-top: 10px;
        vertical-align: middle;
      }
    }
    

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 51px;

      .ip {
        font-size: .2rem;
        color: #999;
        padding: 18px 0;
      }

      .action {
        display: flex;
        align-items: center;

        .btn {
          color: #ccc;
          display: flex;
          align-items: center;
          margin-left: 30px;

          .number {
            font-size: .22rem;
            color: #999;
            margin-left: 4px;
          }
        }
      }
    }

    .question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 12px;
    
      .time {
        color: #999;
        font-size: .22rem;

        .footmark-type {
          padding: 1.5px 3px;
          border-radius: 3px;
          margin-left: 5px;
        }
      }

      .answer {
        color: #FFC000;
        font-size: .24rem;
        border: 0.5px solid #FFC000;
        border-radius: 13.5px;
        padding: 3px 12px;
      }
    }

    .reply-container {
      border-radius: 10px;
      overflow: hidden;

      .reply-item {
        padding: 14px 11px 0;
        background-color: #F5F6F8;

        .userinfo {
          img {
            width: 15px;
            height: 15px;
            vertical-align: middle;
            border-radius: 7.5px;
          }

          span {
            font-size: .24rem;
            color: #282828;
            font-weight: 500;
            vertical-align: middle;
          }
        }

        .reply-content {
          padding-top: 10px;
          line-height: 1.8;
          color: #666;
          font-size: .26rem;
          overflow: hidden;
          word-wrap: break-word;
        }

        .action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 15px;

          .ip {
            font-size: .2rem;
            color: #999;
          }

          .like {
            font-size: .22rem;
            color: #999;
            display: flex;
            align-items: center;

            .number {
              padding-right: 15px;
            }
          }
        }

        .line {
          border-top: 0.5px dashed #999;
        }
      }
    }

    .reply {
      background-color: #FBFBFB;
      border-radius: 10px;
      padding: 10px 15px;
      margin-bottom: 12px;

      .username {
        font-size: .24rem;
        color: #282828;
        font-weight: 600;
        line-height: 12px;
      }

      .reply-contents {
        font-size: .24rem;
        color: #999;
        margin-left: 10px;
        line-height: 12px;
      }
    }
  }
}

.community_sort {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 999;

  .sort-container {
    position: absolute;
    background-color: #060E1D;
    display: inline-block;
    width: 85px;
    height: 132px;
    top: 130px;
    right: 15px;
    border-radius: 8px;

    .sort-list {
      
      .sort-item {
        padding-top: 17px;
        color: white;
        font-size: .26rem;
        text-align: center;
      }
    }
  }
}