.chat-index {
  height: calc(100vh - 44px - env(safe-area-inset-top));

  .chat-announcement {
    position: fixed;
    left: 35px;
    top: calc(env(safe-area-inset-top) + 40px + 8px);
    right: 35px;
    background-color: #FEEDE8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    z-index: 10;

    img {
      width: 18px;
      height: 18px;
      margin: 8px 10px 8px 14px;
    }
    
    .content {
      font-size: .26rem;
      color: #F6511D;
    }
  }

  .service {
    display: flex;
    position: fixed;
    left: 0px;
    right: 0;
    background-color: #f6f8fa;
    bottom: calc(env(safe-area-inset-bottom) + 47px);
    padding: 5px;

    .service-container {
      background-color: white;
      border-radius: 15px;
      padding: 3px 10px;
      margin-left: 5px;

      img {
        width: 23px;
        height: 23px;
        margin-right: 4px;
        vertical-align: middle;
      }

      span {
        font-size: .26rem;
        color: #282828;
        vertical-align: middle;
      }
    }

  }

  .chat-action-wrap {
    display: none;
  }

  .chat-message-list {
    padding: 15px 0 calc(env(safe-area-inset-bottom) + 48px + 40px);
  }

  .chat-message-list-tip {
    font-size: .22rem;
  }

  .chat-message-list-tobottom {
    bottom: calc(100px + env(safe-area-inset-bottom));
  }
  
  .chat-message-list-content .common-parse-session-noti {
    padding: 8px 0;
    font-size: .22rem;
  }

  .chat-message-list-item-wrap {
    padding: 8px 15px;

    .chat-message-list-item-content-box {
      max-width: 75%;

      .chat-message-list-item-content .common-parse-session-text-wrapper a {
        word-break: break-all;
      }

      .chat-message-list-item-body {
        border-radius: 12px !important;
        background-color: white !important;
        font-size: 15px;

        .common-parse-session-reply-wrapper {
          border-bottom: 1px solid #eee;
        }

        video {
          height: 33vw;          
          vertical-align: middle;
        }

        .ant-image,
        .common-parse-session-image .ant-image-img{
          width: auto;
        }

      }
    }
  }

  .chat-message-list-item-avatar{
    width: 46px;
    height: 46px;
  }

  .chat-message-list-item-nick {
    margin-bottom: 8px;
  }

  .ant-avatar {
    width: 46px !important;
    height: 46px !important;
    line-height: 46px !important;
    background-color: #dedede !important;

    .ant-avatar-string {
      line-height: 46px !important;
    }
  }

  .chat-message-list-item-date {
    display: none;
  }

  .chat-message-input {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: white;
    z-index: 9;

    .chat-message-input-icon-box :nth-child(3) {
      display: none;
    }

    .chat-message-input-wrap {
      border-bottom: none;
      border-top: 0.5px solid #dedede;

      .chat-message-input-popup-container {
        // top: -20px;

        .ant-popover-inner {
          border: none;
          box-shadow: none;
          padding: 0;

          .chat-at-member-wrap {
            max-height: 30vh;

            .chat-at-member-item {
              height: auto;
              padding: 5px 15px;
            }
          }
        }

      }

      .chat-message-input-content textarea {
        border: 0.5px solid #dedede;
        margin: 7.5px 10px 4.5px;
        border-radius: 17px;
        padding: 6px 15px;
        height: auto;
      }
    }

  }

  .im-pack-alert {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  
    .im-pack-mask {
      position: fixed;
      z-index: 555;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      transition: all .25s;
      opacity: 0;
    }
  
    .im-pack-container {
      position: relative;
      z-index: 666;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      padding-bottom: env(safe-area-inset-bottom);
      transition: all .25s;
      opacity: 0;
      transform: translateY(100%);
      max-height: 75vh;
    }
  
    &.appear-active,
    &.appear-done,
    &.enter-active,
    &.enter-done {
      .im-pack-mask {
        opacity: 1;
      }
  
      .im-pack-container {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    &.exit-active {
      .im-pack-mask {
        opacity: 0;
      }
  
      .im-pack-container {
        opacity: 0;
        transform: translateY(100%);
      }
    }
  
    &.exit-done {
      display: none;
    }
  
    .title {
      font-size: .36rem;
      font-weight: 500;
      color: #282828;
      text-align: center;
      padding-top: 30px;
    }

    .pack {
      font-size: .28rem;
      text-align: center;
      color: #666;
      padding: 30px;
    }
  
    .done {
      margin: 0px 15px;
      background-color: #ffc000;
      border-radius: 25px;
      height: 50px;
      line-height: 50px;
      color: white;
      font-size: .36rem;
      text-align: center;
      font-weight: 500;
      margin-bottom: 20px;
    }
    
  }

}