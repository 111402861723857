.clusters-index .clusters-container {
  min-height: calc(100vh - env(safe-area-inset-top) - 44px);
  background-color: #F5F6F8;
  padding: 0 15px;
}
.clusters-index .clusters-container .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 40%;
  position: absolute;
}
.clusters-index .clusters-container .empty img {
  display: block;
  width: 50%;
}
.clusters-index .clusters-container .adm-list-item {
  background-color: #F5F6F8;
  margin-top: 10px;
  padding-left: 0;
}
.clusters-index .clusters-container .adm-list-item-content {
  padding-right: 0;
}
.clusters-index .clusters-container .adm-list-item-content-main {
  padding: 0;
  border-radius: 13px !important;
}
.clusters-index .clusters-container .adm-swipe-action-action-button.adm-button {
  padding-left: 14px;
  padding-right: 14px;
}
.clusters-index .clusters-container .item {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 13px;
  position: relative;
}
.clusters-index .clusters-container .item .avatar {
  position: relative;
}
.clusters-index .clusters-container .item .avatar .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 18px;
  height: 16px;
}
.clusters-index .clusters-container .item .content {
  margin-left: 10px;
  flex: 1;
}
.clusters-index .clusters-container .item .content .tname {
  font-size: 0.32rem;
  color: #282828;
}
.clusters-index .clusters-container .item .content .message {
  font-size: 0.26rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.clusters-index .clusters-container .item .count {
  font-size: 0.26rem;
  padding: 0px 5.5px;
  border-radius: 10px;
  text-align: center;
  color: white;
}
.clusters-index .clusters-container .item .top_icon {
  width: 28px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}
.clusters-index .clusters-container .adm-swipe-action-action-button.adm-button {
  margin-left: 5px;
  font-size: 0.26rem;
  color: white;
  --border-radius: 13px;
}
