.mask-index {
  .mask-header {
    display: flex;
    align-items: center;
    padding: 25px;
  
    .mask-header-icon {
      width: 40px;
      height: 40px;
    }
  
    .mask-content {
      padding-left: 10px;
      flex: 1;
      .mask-content-title {
        color: #333;
        font-weight: 700;
      }
      .mask-content-desc {
        font-size: 12px;
        color: #999;
      }
    }
  }
  
  .mask-container {
    height: 230px;

    .swipe-pic {
      position: relative;

      .swipe-pic-index {
        background-color: #3573f0;
        color: white;
        position: absolute;
        left: 0;
        top: 0;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        padding: 1px 8px;
      }
    }
  }

  .mask-pic-index {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 90px;
    background-color: white;
    transition: opacity 750ms;
  
    .mask-pic {
      padding: 0 25px;
    }  
  }
  
  .adm-page-indicator-dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #e0e0e0;
    margin-right: 8px !important;
  }
  
  .adm-page-indicator-horizontal .adm-page-indicator-dot-active {
    width: 8px;
    height: 8px;
    background-color: #b2b2b2;
  }
  
  .adm-swiper {
    overflow: visible;
  }
  
  .adm-swiper-indicator {
    bottom: -25px !important;
    padding-bottom: 46px;
  }
  
  .adm-swiper-slide .adm-swiper-item {
    padding: 0 25px;
    padding-bottom: 50px;
  
    .adm-image-img {
      border-radius: 13px;
    }
  }
}

.copy-mask-index {
  
  .copy-mask-container {
    position: relative;

    .index {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #3573f0;
      padding: 1px 8px;
      border-top-left-radius: 11px;
      border-bottom-right-radius: 11px;
      color: white;
      font-size: 14px;
    }
  }

  .mask-bottom {
    
    .mask-bottom-content {
      padding: 10px 0;

      .mask-bottom-content-desc {
        color: #282828;
        font-weight: 500;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 0;

        .mask-bottom-content-block {
          display: flex;
          align-items: center;
          padding: 0 3px;

          .mask-bottom-icon {
            height: 16px;
            width: 16px;
          }

          .mask-bottom-name {
            color: #3573f0;
            padding-left: 3px;
          }
        }
      }
    }

    .mask-bottom-copy-action {
      background-color: #3573f0;
      border-radius: 10px;
      color: white;
      padding: 10px;
      margin: 15px 25px;
      text-align: center;
      font-size: 16px;
    }

  }
}

.version-mask-index {

  .version-mask-bottom {
    
    .mask-bottom-content {
      padding: 10px 15px;

      .mask-bottom-content-desc {
        color: #282828;
        font-size: 14px;
        text-align: start;
        padding: 2px 0;

        .mask-bottom-content-block {
          display: flex;
          align-items: center;
          padding: 0 3px;

          .mask-bottom-icon {
            height: 16px;
            width: 16px;
          }

          .mask-bottom-name {
            color: #3573f0;
            padding-left: 3px;
          }
        }
      }
    }

    .mask-bottom-copy-action {
      background-color: #3573f0;
      border-radius: 10px;
      color: white;
      padding: 10px;
      margin: 15px 25px;
      text-align: center;
      font-size: 16px;
    }

  }

  .version-mask-container {
    position: relative;
    background-color: #ececec;

    .index {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #3573f0;
      padding: 1px 8px;
      border-top-left-radius: 11px;
      border-bottom-right-radius: 11px;
      color: white;
      font-size: 14px;
    }
  }
}
