.publish-index {
  .container {
    background-color: white;

    .type {
      padding: 25px 15px 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action {

        .text {
          padding: 5px 11px !important;
          background-color: #F5F6F9;
          border-radius: 15px;
          color: #666;
          font-size: .26rem;
          border: none;
          display: inline-block;
        }

        .active {
          color: #FFC000;
          border: 0.5px solid #FFC000;
          background-color: white;
        }

        :last-child {
          margin-left: 15px !important;
        }
      }

      .publish {
        background-color: #FFC000;
        padding: 4px 12px;
        color: white;
        font-size: .26rem;
        border-radius: 15px;
      }

    }

    .video {
      background-color: #F2F3F5;
      border-radius: 10px;
      height: 215px;
      margin: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      margin-top: 5px;

      .upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .text {
          color: #999;
          font-size: .26rem;
          margin-top: 4px;
        }

        .file {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent;
          opacity: 0;
        }

        .mask {
          background-color: #000000;
          opacity: 0.75;
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 10px;
          overflow: hidden;

          .progress {
            color: white;
            font-size: .28rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }

        .player {
          width: 100%;
          position: absolute;
          z-index: 10;
          border-radius: 10px !important;
          overflow: hidden;
          top: 0;
          bottom: 0;

          .change {
            border: 0.5px solid white;
            border-radius: 12px;
            padding: 3px 9px;
            font-size: .22rem;
            margin-left: 15px;
            margin-top: 15px;
            color: white;
            position: absolute;
            top: 0;
          }

          .change-file {
            position: absolute;
            top: 0;
            margin-top: 15px;
            margin-left: 15px;
            opacity: 0;
            width: 70px;
          }

          .delete {
            padding: 15px;
            position: absolute;
            top: 0;
            right: 0;

            img {
              width: 17px;
              height: 17px;
            }
          }

        }

      }

      .rule {
        position: absolute;
        bottom: 10px;
        right: 10px;

        img {
          width: 12px;
          height: 12px;
          object-fit: cover;
        }

        img,span {
          vertical-align: middle;
          color: #999;
          font-size: .24rem;
        }
      }
    }

    .title {
      border-bottom: 0.5px solid #D7D2D2;
      display: flex;
      align-items: center;
      padding: 0 15px;
      justify-content: space-between;

      input {
        font-size: .42rem;
        font-weight: 600;
        padding: 10px 0;
        border: none;
        flex: 1;
      }
      
      .length {
        color: #999;
        font-size: .22rem;
        padding-left: 10px;
      }
    }

  }
}