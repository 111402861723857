.hangup-feedback-index {
  background-color: white;

  .hangup-feedback-container {
    padding: 0 35px;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 25px 0;

      .tag {
        background-color: #FFC000;
        font-size: 11px;
        border-radius: 3px;
        color: white;
        padding: 0px 3px;
      }

      .title {
        font-size: 14px;
        color: #282828;
        padding-left: 2px;
      }
    }

    .list {

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 25px;
        .icon {
          width: 20px;
          height: 20px;
        }

        .text {
          font-size: 14px;
          color: #282828;
          padding-left: 7px;
        }
      }
    }

    .content {
      border: 1px solid #999;
      border-radius: 13px;
      height: 130px;
      font-size: 14px;
      width: 100%;
      padding: 15px 10px;
    }

    .desc {
      font-size: 14px;
      color: #282828;
      padding: 20px 0;
      display: inline-block;
    }

    .upload {
      position: relative;
      width: 70px;
      height: 70px;
      
      .file {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .submit {
      background-color: #FFC000;
      color: white;
      border-radius: 25px;
      padding: 15px;
      text-align: center;
      font-weight: 18px;
      font-weight: 500;
      margin-top: 65px;
    }
  }
}