.extend-game-index {
  background-color: #f5f6f8;
  padding: 15px;
}
.extend-game-index .extend-game-content .entity {
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
  position: relative;
}
.extend-game-index .extend-game-content .entity .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.extend-game-index .extend-game-content .entity .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.extend-game-index .extend-game-content .entity .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.extend-game-index .extend-game-content .entity .icon .iconlable {
  position: absolute;
  color: #fff;
  padding: 0 0.12rem;
  font-size: 12px;
  font-size: 0.22rem;
  background-image: linear-gradient(#009CFF, #5537FF);
  border-bottom-right-radius: 8px;
  border-top-left-radius: 12px;
  bottom: 0;
  right: 0;
}
.extend-game-index .extend-game-content .entity .icon .iconlable.jx {
  background-image: linear-gradient(#E70A20, #B30705);
}
.extend-game-index .extend-game-content .entity .text {
  flex: 1;
  width: 0;
  font-size: 0.2rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  position: relative;
}
.extend-game-index .extend-game-content .entity .idx {
  position: absolute;
  left: 0;
  top: 0;
}
.extend-game-index .extend-game-content .entity .idx .index-icon {
  width: 36px;
}
.extend-game-index .extend-game-content .entity .idx .index-text {
  position: absolute;
  top: 2px;
  color: white;
  font-size: 14px;
}
.extend-game-index .extend-game-content .entity .name {
  font-weight: bold;
  overflow: hidden;
  font-size: 0.3rem;
  color: #282828;
  display: flex;
  align-items: center;
}
.extend-game-index .extend-game-content .entity .name span {
  padding: 0 0.1rem;
  color: #956528;
  background: #EEBE75;
  font-size: 0.24rem;
  margin-right: 2px;
  border-radius: 3px;
  background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
}
.extend-game-index .extend-game-content .entity .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.1rem;
}
.extend-game-index .extend-game-content .entity .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.34;
}
.extend-game-index .extend-game-content .entity .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  font-weight: normal;
}
.extend-game-index .extend-game-content .entity .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.extend-game-index .extend-game-content .entity .info1 .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.extend-game-index .extend-game-content .entity .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.22rem;
  color: #666;
}
.extend-game-index .extend-game-content .entity .info3 {
  border-radius: 3px;
  font-size: 0.22rem;
}
.extend-game-index .extend-game-content .entity .info3 .first-text {
  padding: 1px 3px;
  color: #fff;
  border-radius: 3px;
}
.extend-game-index .extend-game-content .entity .info3 .second-text {
  border: 1px solid;
  padding: 0 0.1rem;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
}
.extend-game-index .extend-game-content .entity .tag {
  display: inline;
  padding-left: 5px;
}
.extend-game-index .extend-game-content .entity .tag:nth-child(1) {
  color: #F5B380;
  padding-left: 0;
}
.extend-game-index .extend-game-content .entity .tag:nth-child(2) {
  color: #ACD498;
}
.extend-game-index .extend-game-content .entity .tag:nth-child(3) {
  color: #89ACDA;
}
.extend-game-index .extend-game-content .entity .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.extend-game-index .extend-game-content .entity .text .name {
  font-size: 0.32rem;
}
.extend-game-index .extend-game-content .entity .text .info1 {
  font-size: 0.24rem;
  padding: 2px 0;
}
.extend-game-index .extend-game-content .entity .text .info3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.065rem;
}
.extend-game-index .extend-game-content .entity .text .info3 span {
  font-size: 0.24rem;
}
.extend-game-index .extend-game-content .entity .text .info3 .first-text {
  font-size: 0.2rem;
}
.extend-game-index .extend-game-content .entity .text .info3 .second-text {
  margin-right: 5px;
  font-size: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.extend-game-index .extend-game-content .entity .text .info3 .speed {
  margin-right: 5px;
  padding: 0 4px;
  background-color: #FCF8ED;
  font-size: 10px;
  color: #D79863;
  border-radius: 1px;
  flex-shrink: 0;
}
.extend-game-index .extend-game-content .entity .text .discount {
  background-image: none;
  color: #FF3F44;
  top: calc((100% - 20px) / 2);
  font-size: 11px;
  width: auto;
  padding: 0;
  position: absolute;
  right: 0;
}
.extend-game-index .extend-game-content .entity .text .discount span {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  top: 1px;
}
.extend-game-index .extend-game-content .entity .icon {
  height: 78px;
  width: 78px;
}
