.sign-gift {
  .container {
    .content {
      .header {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        background-color: white;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: -25px;
        padding: 15px 0;
        position: sticky;
        top: calc(env(safe-area-inset-top) + 40px);
        z-index: 20;

        .item {
          font-size: 18px;
          color: #666;

          &.active {
            color: #282828;
          }

          &.active::after {
            content: '';
            width: 27px;
            height: 3px;
            background-color: #FF4746;
            border-radius: 1.5px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            right: 0;
            display: block;
          }
        }
      }

      .list {

        .list-header {
          display: flex;
          flex-wrap: wrap;
          position: sticky;
          top: calc(env(safe-area-inset-top) + 40px + 60px);
          background-color: white;
          z-index: 20;

          .tag {
            padding: 3px 16px;
            background-color: #F4F4F4;
            border-radius: 16px;
            font-size: 13px;
            color: #282828;
            margin-right: 10px;
            margin-bottom: 10px;

            &.active {
              background-color: #FF4746;
              color: white;
            }
          }
        }

        .game-list {
          padding: 0 15px 15px;
          background-color: white;

          .games {
            margin-top: 5px;

            .item {
              background-color: white;
              border-radius: 13px;
              margin-bottom: 15px;
  
              .game {
                display: flex;
                align-items: center;
  
                .icon {
                  height: 75px;
                }
                
                .text {
                  padding-left: 10px;
                  flex-grow: 1;
  
                  .name{
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    color: #282828;
                  }
  
                  .info1 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #999;
                    font-size: 11px;
                    padding: 2px 0 5px;
                  }
  
                  .info3 {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 12px;
                    
                    .first-text {
                      font-size: 10px;
                      padding: 1px 3px;
                      color: #fff;
                      border-radius: 3px;
                    }
  
                    .second-text {
                      margin-right: 5px;
                      font-size: .2rem;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      border: 1px solid;
                      padding: 0 .1rem;
                      border-left: 0;
                      border-top-right-radius: 3px;
                      border-bottom-right-radius: 3px;
                      margin-left: -2px;
                    }
  
                    .speed {
                      margin-right: 5px;
                      padding: 0 4px;
                      background-color: #FCF8ED;
                      font-size: 10px;
                      color: #D79863;
                      border-radius: 1px;
                      flex-shrink: 0;
                    }
                  }
                }
  
                .action {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .btn {
                    background-color: #FF4746;
                    color: white;
                    border-radius: 14px;
                    width: 66px;
                    height: 28px;
                    text-align: center;
                    line-height: 28px;
                    font-size: 12px;

                    &.disable {
                      background-color: #D9D9D9;
                    }
                  }

                  .btn-desc {
                    color: #FF4746;
                    font-size: 10px;
                    margin-top: 2px;
                  }
                }
                
              }
  
              
            }
          }

          .receive-alert {

            .alert {
              height: 100vh;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
          
              .container {
                background-color: #F3F4F8;
                border-radius: 13px;
                width: 75%;
          
                .title {
                  font-size: 17px;
                  font-weight: 500;
                  color: #282828;
                  text-align: center;
                  padding: 20px;
                }
          
                .item{
                  background-color: white;
                  border-radius: 13px;
                  padding: 10px;
                  display: flex;
                  align-items: center;
                  margin: 0 15px 10px;

                  .icon {
                    flex-shrink: 0;
                    border-radius: 9px;
                    background-color: #FEF3ED;
                  }

                  .content {
                    padding-left: 10px;
                    flex-grow: 1;
                    overflow: hidden;

                    .key {
                      font-size: 13px;
                      color: #282828;
                    }

                    .desc {
                      font-size: 11px;
                      color: #999;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .pack {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap
                      }

                      .copy {
                        font-size: 10px;
                        color: var(--MainColor);
                        flex-shrink: 0;
                      }
                    }
                  }
                }
                    
                .open {
                  background-color: var(--MainColor);
                  color: white;
                  font-size: 15px;
                  font-weight: 500;
                  padding: 10px 16px;
                  border-radius: 22px;
                  margin: 25px 28px 20px;
                  text-align: center;
                }
              }
              .close {
                width: 22px;
                padding-top: 10px;
              }
            }
          }
        }

        .receive-list {
          padding: 0 15px 15px;
          background-color: white;

          .games {
            margin-top: 5px;

            .item {
              background-color: white;
              border-radius: 13px;
              margin-bottom: 15px;
  
              .game {
                display: flex;
                align-items: center;
  
                .icon {
                  height: 75px;
                }
                
                .text {
                  padding-left: 10px;
                  flex-grow: 1;
  
                  .name{
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    color: #282828;
                    display: flex;
                    align-items: center;

                    .nameRemark {
                      background-color: #F3F4F8;
                      padding: 1px 4px;
                      border-radius: 3px;
                      margin-left: 5px;
                      font-size: 10px;
                      color: #999;
                      font-weight: 400;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                  }
  
                  .info1 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #999;
                    font-size: 11px;
                    padding: 2px 0;

                    &.voucher {
                      color: #FE4356;
                    }                    

                    &.gift {
                      color: #888;
                    }
                  }
  
                  .voucher {
                    font-size: 10px;
                    color: #888;
                  }

                  .gift {
                    font-size: 11px;
                    color: #999;
                  }
                }
  
                .btn {
                  background-color: #FF4746;
                  color: white;
                  border-radius: 14px;
                  width: 66px;
                  height: 28px;
                  text-align: center;
                  line-height: 28px;
                  font-size: 12px;

                  &.expired {
                    background-color: #D9D9D9;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}