.index-home-search {
  margin: 0 15px;
  padding: 9px 10px;
  background-color: #eee;
  border-radius: 20px;

  .icon {
    margin-left: 5px;
    margin-right: 5px;
    color: #999;
    height: 14px;
  }

  .text {
    font-size: 12px;
    color: #999;
  }
}

.index-home-banner {
  margin: 10px 15px;

  .banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 13px;
  }
}

.index-home-top-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 15px;

  .menus-div {
    flex: 1;
    width: 100%;
  }

  .index-home-top-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    flex: 1;
    border-radius: 8px;
    
    .content {
      padding: 10px 0 10px 20px ;

      .title {
        font-size: 15px;
        color: #282828;
        font-weight: 600;
      }
  
      .desc {
        font-size: 10px;
        color: #999;
        padding-top: 1px;
      }
  
    }
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 20px;
    }
  }

  .right-space {
    margin-right: 10px;
  }

  .top-space {
    margin-top: 10px;
  }

  .index-home-top-item .left {
    width: 100%;
    background-color: #FAFAFA;

    .index-home-top-content {
      flex-direction: column;
      width: 100%;
      flex: 1;
      margin: 0;

      .menus-div {
        margin-right: 0px;
      }
    }
  }

  .right {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contents {
      padding: 15px 0 12px 0;

      .title {
        font-size: 15px;
        color: #282828;
        font-weight: 600;
        text-align: center;
      }
  
      .desc {
        font-size: 10px;
        color: #999;
        padding-top: 1px;
        text-align: center;
      }
    }

    .right-icon {
      width: 39px;
      height: 39px;
      margin: 0 0 20px 0;
    }
  }
}

.index-want-play {
  // background-image: linear-gradient(#F0FCFF,white);
  border-radius: 13px;

  .index-want-play-header-icon {
    padding-top: 20px;
    padding-left: 13px;
    
    img {
      height: 21px;
    }
  }

  .index-want-play-header-desc {
    color: #999;
    font-size: 13px;
    margin-top: 5px;
  }

  .index-want-play-swiper {
    padding-top: 5px;

    .index-want-play-swiper-dot {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;

      .index-want-play-swiper-dot-item {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        margin-left: 5px;
        background-color: #AAAAAA;
      }
    }

    .index-want-play-empty-interval {
      height: 20px;
    }

    .swiper-index {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 13px;

      .item {
        width: calc(calc(100% - 10px) / 2);
        overflow: hidden;

        &:nth-child(2n-1) {
          margin-right: 10px;
        }

        .icon {
          border-radius: 8px;
          height: 92px;
          width: 100%;
          margin-bottom: 10px;
          margin-top: 11px;
          object-fit: cover;
        }

        .gameName {
          color: #282828;
          font-size: 16px;
          font-weight: 600;
          padding-right: 7px;
        }

        .nameRemark{
          color: #FF3F44;
          font-size: 12px;
          font-weight: 500;

          .text {
            font-size: 8px;
          }
        }

        .classify {
          font-size: 12px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 5px;
        }

        .tags {
          display: flex;
          align-items: center;
          padding-top: 5px;

          .speed {
            margin-right: 5px;
            padding: 0 4px;
            background-color: #FCF8ED;
            font-size: 10px;
            color: #D79863;
            border-radius: 1px;
            flex-shrink: 0;
          }
        }

        .desc {
          color: #AAAAAA;
          font-size: 9px;
          margin-top: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

      }
    }
  }
}

.index-all-search {
  border-radius: 13px;

  .index-all-search-header-icon {
    padding-top: 20px;
    padding-left: 13px;
    
    img {
      height: 21px;
    }
  }

  .index-all-search-container {
    margin: 15px 13px 20px;
    column-count: 3;
    column-gap: 10px;

    .index-all-search-container-item {
      display: flex;
      align-items: center;
      justify-content: center;
      // justify-content: space-between;
      // padding: 7px 15px;
      padding: 7px 7px;
      border-radius: 16px;
      font-size: 13px;
      color: #282828;
      background-color: #F3F3F3;
      
      .key {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: .1rem;
      }
    }
  }

}