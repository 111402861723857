.wechat-alert {
  position: fixed;
  z-index: 1000;
  inset: 0px;
  top: 0;
  background-color: #444444DD;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 80% 10px;
}

.qq-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10010;
  background-color: #444444DD;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  line-height: 1.8;
  .tips {
    display: flex;
    justify-content:center;
    font-size: 26px;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 18px;
    .ua {
      color: orange;
    }
  }
  .href {
    margin-top: 1rem;
    display: flex;
    justify-content:center;
  }
  .copybtn{
    display: flex;
    justify-content:center;
    border: 1px solid;
    width: 100px;
    margin: 0 auto;
    border-radius: 15px;
    margin-top: 10px;
  }
}

.body-overflow-hidden{
  overflow: hidden;
}