@import '../../node_modules/antd-mobile/2x/es/global/global.css';
@import '../../node_modules/antd-mobile/2x/cjs/global/global.css';
@import '../../node_modules/antd-mobile/cjs/global/global.css';
@import '../../node_modules/antd-mobile/es/global/global.css';
@import '../../node_modules/antd/es/style/default.css';
@import '../../node_modules/antd/lib/style/default.css';
@import "reset.css";
@import '../view/game/gamesign/css/gamesign.less';

.game-sign-title .adm-modal-title {
  font-size: 17px;
  font-weight: 500;
  color: #282828;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html #webpack-dev-server-client-overlay {
  display: none !important;
}

.info1 {
  transform: translateY(-1px);
}

.speed {
  color: #D4823D !important;
}

.ant-popover-inner {
  padding-bottom: 20px;
}

.ant-modal-root {
  display: none;
}

.ant-message {
  top: calc(env(safe-area-inset-top) + 8px) !important;
}

.ant-image-mask .ant-image-mask-info {
  display: none;
}

.ant-image-preview-operations {
  padding-top: env(safe-area-inset-top) !important;
  padding-right: 10px !important;
  background-color: rgba(0, 0, 0, 0.35) !important;

  .ant-image-preview-operations-operation{
    padding: 4px 8px !important;
  }

  .ant-image-preview-operations-operation:nth-child(5) {
    display: none;
  }

  .ant-image-preview-operations-operation:nth-child(4) {
    display: none;
  }
}

.ant-image-preview-content .ant-image-preview-body .ant-image-preview-img-wrapper .ant-image-preview-img {
  height: auto;
  max-width: 100vw;
  max-height: 100vh !important;
}

.common-parse-session-image img {
  height: auto !important;
  max-height: 200px !important;
}

.adm-popover-menu-list {
  min-width: 0 !important;
}

.ant-image-preview-mask {
  background-color: black !important;
}

.adm-popover-inner {
  border-radius: 6px !important;
}

.adm-popover-menu-list-inner {
  display: flex;

  .adm-popover-menu-item {
    border-right: 0.5px solid #aaa;
    padding: 0 5px;

    .adm-popover-menu-item-text {
      padding: 6px 9px;
      font-size: 13px;
      text-align: center;
    }
  }
}

:root {
  --adm-color-primary: #646464 !important;
}

div * {
  -webkit-user-select: text;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.root,
.width {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  @media (orientation: landscape) {
    max-width: 100%;
  }

  //判断是否是pc
  @media screen and (min-width:960px) {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

.w-e-text-placeholder {
  font-style: normal !important;
}

.w-e-menu-tooltip-v5:hover:before {
  opacity: 0 !important;
}

.w-e-menu-tooltip-v5:hover:after {
  opacity: 0 !important;
}

.safeareatest {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

@import "dialog.css";

.activity-tips {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .75);
  transition: all .25s;

  img {
    display: block;
    max-width: 75vw;
    max-height: 75vh;
  }
}

.desktop-tips {
  position: fixed;
  z-index: 777;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // justify-content: end;
  justify-content: flex-end;
  transition: all .25s;
  // overflow: hidden!important;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;

  &:before {
    content: '';
    display: block;
    position: fixed;
    z-index: 888;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
  }

  .container {
    z-index: 999;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    color: #fff;

    .entity {
      // max-width: 75vw;

      position: relative;
      z-index: 666;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      // padding: 15px;
      // padding-top: 35px;
      padding-top: 20px;
      padding-left: 35px;
      padding-right: 35px;
      // padding: 35px 35px .3rem 35px;
      // height: 50vh;
      display: flex;
      flex-direction: column;
      padding-bottom: env(safe-area-inset-bottom);
      transition: all .25s;
      max-width: 720px;
      margin: 0 auto;
      // width: 100%;
      // max-width: 100vw;
      width: 100%;
      max-width: 100vw;
      // height: 10rem;
      height: 9.4rem;

      >img {
        display: block;
        width: 100%;
      }

      .top {
        display: flex;
        // border-bottom: 1px solid #eee;
        padding-bottom: 10px;


        .icon {
          flex-shrink: 0;
          // width: 1.2rem;
          // height: 1.2rem;
          width: 1.1rem;
          height: 1.1rem;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          justify-items: center;
          margin-right: 10px;

          img {
            border-radius: 8px;
          }
        }

        .text {
          display: flex;
          width: 3.5rem;
          align-items: center;
        }
      }

      .body {
        width: 100%;
        // padding: 10px;
        // padding: 10px 60px;
        // padding: 10px 50px;
        padding: 10px 1.1rem;
        overflow: hidden;
        background-color: #fff;
        display: flex;
        align-items: center;

        .icon {
          // width: 50px;
          // height: 50px;
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 8px;
          margin-right: 7.5px;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .info {
          flex: 1;
          width: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #000;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333;
          font-size: .22rem;
        }

        .url {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
          color: #666;
          margin-top: 5px;
          font-size: .22rem;
        }
      }

      .end-new {
        display: flex;
        padding-bottom: 10px;
        color: #282828;
        padding-top: .1rem;

        .icon {
          flex-shrink: 0;
          width: 0.87rem;
          // display: flex;
          // align-content: center;
          // align-items: center;
          // justify-content: center;
          // justify-items: center;
          margin-right: 10px;
          margin-top: .05rem;
        }

        .text {
          // padding-top: .3rem;
          // font-size: .22rem;
          padding-top: 0;
          font-size: .28rem;

          span {
            color: #54C5CD;
          }
        }
      }
    }

    @media screen and (max-height: 736px) {
      .entity {
        // max-width: 65vw;
      }
    }

    @media screen and (max-height: 667px) {
      .entity {
        // max-width: 60vw;
      }
    }

    @media screen and (min-height: 668px) {
      .entity {
        width: 100%;

        .body {
          padding: 10px 1.1rem;
        }
      }
    }

    .end {
      font-size: 15px;
      margin-top: 7.5px;
      font-weight: bold;

      span {
        color: #ffc000;
      }
    }
  }
}

.pull {
  &-to-refresh {
    padding: 10px;
    color: #ccc;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-200%);

    &:before {
      content: '释放刷新';
    }
  }

  &-to-load {
    padding: 10px;
    color: #ccc;
    text-align: center;

    &:before {
      content: '拉下加载更多';
    }

    &.nomore {
      &:before {
        content: '没有更多的数据了';
      }
    }
  }
}

.screen-index {
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #fff;
  transition: all .5s;
  display: none;

  &.active {
    display: block;
  }

  .screen {
    &-body {
      padding-bottom: 55px;
      position: relative;

      &-item {
        position: relative;
        display: none;

        &.active {
          display: block;
        }
      }
    }

    &-menu {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: env(safe-area-inset-bottom);
      background-color: #fff;
      border-top: 1px solid #eee;
      padding-top: 4px;
      transition: all .25s;

      &-container {
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      &-item {
        flex: 1;
        flex-shrink: 0;
        text-align: center;
        padding: 5px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        transition: all .25s;
        color: #282828;
        .icon {
          display: none;
          height: 25px;
          background: center no-repeat;
          background-size: contain;
        }

        .icon-dark {
          display: block;
        }

        &.active {
          // color: #F5842D;

          .icon-dark {
            display: none;
          }

          .icon-highlight {
            display: block;
          }
        }
      }
    }
  }
}

.theme-dark {
  .screen-index {
    background-color: #000;
  }

  .screen {
    &-menu {
      background-color: #000;

      &-item {
        color: #fff;

        .icon-dark {
          display: none;
        }

        .icon-white {
          display: block;
        }

        &.active {
          .icon-white {
            display: none;
          }

          .icon-highlight {
            display: block;
          }
        }
      }
    }
  }
}

@import "navigation.css";

@import "util.css";

@import "index.css";
@import "game.css";
@import "task.css";
@import "service.css";
@import "faq.css";
@import "search.css";
@import "trade.css";
@import "article.css";
@import "server.css";
@import "user.css";
@import "feedback.css";
@import "account.css";
@import "voucher.css";
@import "gift.css";
@import "comment.css";
@import "qa.css";
@import "recovery.css";
@import "rebate.css";
@import "ptb.css";
@import "message.css";
@import "credit.css";
@import "invite.css";
@import "save.css";
@import "transfer.css";
@import "welfare.css";
@import "country.css";
@import "video.css";
@import "party.css";
@import "reserved.css";
@import "around.css";

.view-end {
  color: #a2a2a2;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  &:before,
  &:after {
    width: 10%;
    margin: 0 5%;
    content: '';
    display: block;
    height: 0.5px;
    background-color: #ccc;
  }
}

.view-img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;

  .swiper-container {
    height: 100%;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 5px;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }

  .img {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.view-iframe {
  width: 100%;
  height: calc(100vh - 40px - env(safe-area-inset-top));
  position: relative;
  border-top: 1px solid transparent;
  transition: all .25s;

  @media (orientation: landscape) {
    height: calc(100vh - env(safe-area-inset-top));
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background-color: transparent;
    transition: all .25s;

    &.loading {
      background-color: #ffc000;
    }

    &.loaded {
      background-color: transparent;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.iframe-roam-screen {
  // height: calc(100vh - env(safe-area-inset-top));
  height: calc(100vh);
}

.root .video-react .video-react-big-play-button {
  opacity: 0;
  display: none;
}

.video-react.video-react-fullscreen {
  .video-react-control-bar {
    bottom: env(safe-area-inset-bottom);
  }
}

.view-video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  .video-react {
    .video-react-control-bar {
      bottom: 0;
    }
  }

  .video-react.video-react-fullscreen {
    .video-react-control-bar {
      bottom: env(safe-area-inset-bottom);
    }
  }
}

.am-modal-transparent {
  width: auto;
  min-width: 75%;
  max-width: 85%;
}

.am-modal-alert-content {
  text-align: left;
}

.ant-image {
  width: 100%;
  height: 100%;
}

.van-overflow-hidden {
  overflow: hidden !important;
}

.game-view-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  // background-color: #fff;
  // border-bottom: 1px solid #eee;
  transition: all .1s;
  padding-top: env(safe-area-inset-top);
  max-width: 720px;
  margin: 0 auto;

  .background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: all .1s;
    border-bottom: 1px solid #ededed;
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 12.5px;
    height: 40px;
    overflow: hidden;
    z-index: 2;

    .back {
      font-size: 18px;
    }

    .title {
      flex: 1 1;
      width: 0;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: .36rem;
      color: #282828;
      padding: 0 10px;

      .game-nav-head {
        // border-bottom: 1px solid #eee;
        // padding-right: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
      
      
        &.fixed {
          position: fixed;
          z-index: 100;
          top: calc(env(safe-area-inset-top) + 40px);
          left: 0;
          right: 0;
          background-color: #fff;
          margin-top: 0;
        }
      
        .game-nav-head-item {
          float: left;
          text-align: center;
          padding-bottom: 10px;
          position: relative;
          transition: all .25s;
          // font-size: .28rem;
          font-size: .3rem;
      
          &.game-nav-comment {
            display: flex;
            // align-items: center;
            justify-content: center;
            align-items: center;
            position: relative;
          }
      
          &.game-nav-tools {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 15px;
            background-color: #ffc000;
            opacity: 0;
            transition: all .25s;
          }
      
          &.active {
            // color: #000;
            // font-size: .32rem;
            font-size: .34rem;
            font-weight: bold;
      
            &:after {
              opacity: 1;
            }
          }
      
          .count-head {
            font-size: 10px;
            padding-left: .1rem;
            // color: #999;
            position: absolute;
          }

          .count-head-3 {
            right: 10%;
          }

          .count-head-4 {
            right: 2px;
          }
      
          &.active {
            .count-head {
              font-weight: normal;
            }
          }
        }

        .game-nav-width3 {
          width: 33%;
        }

        .game-nav-width4 {
          width: 25%;
        }
      }
    }
    .operate {
      font-size: 18px;
      display: flex;
      justify-content: flex-end;

      .share-img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .white-container {
    .back{
      color: #fff;
      font-size: 18px;
    }
    .title {
      color: #fff;
    }
    .operate {
      color: #fff;
      font-size: 18px;
    }
  }
}

.dialog-type-container {
  position: fixed;
  height: 100vh;

  .dialog-type-inner-content {
    width: 90vw;
    border-radius: 13px;
  
    .close-btn {
      padding: 15px;
    }
  
    .dialog-title {
      font-size: 4.5vmin;
      color: #333;
      font-weight: 500;
      margin-top: -5px;
      margin-bottom: 40px;
    }
  
    .submit-btn {
      margin: 0;
      background: #ffc000;
      margin-top: 40px;
      border-radius: 25px;
      width: 70vw;
      font-weight: 400;
    }
  
    .agreement {
      display: flex;
      margin: 20px 0;
  
      .check-box {
        width: 18px;
        height: 19px;
        margin-right: 5px;
        display: inline;
      }
    
      .checke-0,
      .checke-1 {
        display: none;
      }
  
    }
  
  }
}












