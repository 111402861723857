.worry-free-index {
  .worry-free-container {
    margin: 15px;

    .top-noti {
      border: 0.5px solid #ECECEC;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px;
      
      .noti-icon {
        height: 16px;
        margin-right: 4px;
      }

      .noti-text {
        color: #282828;
        font-size: 13px;
        
      }

      .active {
        color: #FFC000;
      }

    }

    .game-content {
      border: 0.5px solid #ECECEC;
      border-radius: 8px;
      margin-top: 10px;

      .game-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        border-bottom: 0.5px solid #f3f3f3;

        .game-icon {
          height: 50px;
        }

        .game-name {
          color: #282828;
          font-size: 16px;
          padding-left: 15px;
          display: flex;
          align-items: center;

          .nameRemark {
            background: #f3f4f8;
            color: #999;
            font-size: .22rem;
            padding: 0 .1rem;
            margin-left: 5px;
            border-radius: 2px;
          }
        }
      }

      .game-no-pay {
        padding: 0 15px;
        padding-top: 15px;

        .game-time-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 15px;

          .time-item-key {
            font-size: 14px;
            color: #282828;
          }

          .time-item-value {
            font-size: 14px;
            color: #999;
          }
        }
      }

      .game-pay {
        padding: 15px;

        .pay-title {
          font-size: 14px;
          color: #282828;

          span {
            color: #666;
            font-size: 12px;
          }
        }

        .account {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 0.5px solid #ececec;
          border-radius: 8px;
          padding: 10px 15px;
          margin: 15px 0;

          .account-name {
            font-size: 14px;
            color: #666;
          }
        }

        .pay-item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pay-item-key {
            font-size: 14px;
            color: #282828;
            padding-bottom: 10px;
          }
        }

        .pay-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 0.5px solid #ECECEC;
          padding-top: 10px;

          .pay-amount-key {
            color: #282828;
            font-size: 14px;
          }

          .pay-amount-value {
            color: #FF5E00;
            font-size: 14px;
          }
        }
      }

    }

    .game-notice {
      margin: 25px 20px;

      .notice-top {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .notice-top-icon {
          height: 2px;
          padding: 0 20px;
        }

        .notice-top-title {
          font-size: 14px;
          color: #282828;
          flex-shrink: 0;
        }
      }

      .notice-content {
        margin-top: 15px;

        .text-normal {
          color: #666;
          font-size: 13px;
        }

        .active {
          color: #FFC000;
        }
      }

      .notice-bottom {
        position: relative;
        margin-top: 15px;
        border-radius: 8px;
        border: 0.5px solid #ECECEC;
        padding: 10px 9px;

        .notice-line {
          height: 56px;
          position: absolute;
          top: 21px;
          left: 15px;
          bottom: 0;
        }

        .notice-bottom-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .notice-bottom-item-icon {
            height: 13px;
            margin-right: 7px;
          }

          .notice-bottom-content {
            font-size: 13px;
            color: #666;
            padding: 3px 0;

            span {
              color: #FFC000;
            }
          }
        }
      }
    }

    .handle-desc {
      text-align: center;
      color: #FB1414;
      font-size: 11px;
      padding-bottom: 5px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: calc(40px + 50px);  
    }

    .handleAction {
      color: white;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding: 11px;
      position: fixed;
      border-radius: 25px;
      left: 15px;
      right: 15px;
      bottom: 37px;
    }

    .handleAction0, .handleAction1 {
      background-color: #FFC000;
    }

    .handleAction2 {
      background-color: #D9D9D9;
    }

    .handleAction3 {
      display: none;
    }

    .worry-content {
      border: 0.5px solid #ECECEC;
      border-radius: 8px;
      margin-top: 10px;
      padding: 15px 15px 0;

      .worry-content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        .worry-content-item-key {
          font-size: 14px;
          color: #282828;
        }

        .worry-content-item-value {
          font-size: 14px;
          color: #999;
        }
      }
    }

    .worry-free-alert {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 999;

      .alert-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 0 20px;
        height: 100%;

        .alert-content-top-icon {
          vertical-align: middle;
          height: 162px;
        }

        .alert-content-top-text {
          position: relative;
          left: 48px;
          bottom: 30px;
          font-size: 11px;
          color: #999;
        }

        .alert-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // margin: 0 25px;
          background-color: white;
          margin-top: -2px;
          padding: 0 20px 20px;
          width: 284.5px;

          .icon {
            height: 41px;
            padding-right: 8px;
          }

          .content {
            .title {
              color: #282828;
              font-size: 14px;
              font-weight: 500;
            }
            .desc {
              font-size: 11px;
              color: #666;
              span {
                color: #FFC000;
              }
            }
          }
        }

        .alert-action {
          margin: 0px 25.5px 20px 25.3px;
          background-color: white;
          text-align: center;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
          width: 284.5px;
          margin-top: -1px;

          .action {
            color: white;
            font-size: 16px;
            font-weight: 500;
            background-color: #FFC000;
            width: 220px;
            margin: 0 auto;
            padding: 12px 0;
            border-radius: 25px;
            margin-bottom: 33px;
          }
        }

        .alert-cancel {
          display: flex;
          align-items: center;
          text-align: center;
          margin: 0 auto;

          .icon {
            height: 17px;
          }

          .text {
            color: #999;
            font-size: 12px;
            padding-left: 4px;
          }
        }
      }
    }
  }
}

.worry-free-space-index {

  .worry-free-space-container{
    padding: 0px 30px;

    .space-top {
      text-align: center;
      font-size: 19px;
      color: #282828;
      padding: 20px;
    }

    .close-action {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;
    }

    .space-desc {
      font-size: 14px;
      color: #666;

      span {
        color: #FFC000;
      }
    }

    .space-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border: 0.5px solid #ECECEC;
      border-radius: 8px;
      margin: 15px 0;

      .space-content-key {
        font-size: 14px;
        color: #666;
      }

      .space-content-input {
        border: none;
      }

      .space-content-action {
        color: #FFC000;
      }

    }

    .space-action {
      background-color: #FFC000;
      color: white;
      font-size: 18px;
      font-weight: 500;
      padding: 11px 20px;
      border-radius: 25px;
      text-align: center;
      margin-top: 30px;
    }

    .space-cancel {
      text-align: center;
      color: #666;
      font-size: 14px;
      padding: 12px;
      margin-bottom: 10px;
    }
  }

}