.index-newgame {
  background-color: #FAFAFA;
  padding-top: calc(env(safe-area-inset-top) + 50px);
  overflow: hidden;

  .content {

    .imgs {
      overflow: hidden;
      // margin: 0 15px;

      .swiper-container {
        width: 90%;
        overflow: visible;
      }

      .swiper-slide {
        transform: scale(.95);
        transition: all .25s;
      }
      .swiper-slide-active {
        transform: scale(1);
      }

      .swiper-pagination-bullets {
        width: auto;
        left: auto;
        right: 10px;
        bottom: 5px;

        .swiper-pagination-bullet {
          height: 4px;
          border-radius: 2px;
          background-color: white;
          opacity: 0.5;
        }

        .swiper-pagination-bullet-active {
          background-color: #ffc000;
          width: 13px;
          border-radius: 2px;
          opacity: 1;
        }

      }
      
      
      .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 2px;
      }

      .banner {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        background-size: 100% 100%;
        border-radius: 0px;
        overflow: hidden;

        border-radius: 8px;
        padding-top: 52%;
        object-fit: cover;

        .game {
          position: absolute;
          z-index: 22;
          bottom: 0;
          left: 0;
          right: 0;
          background-image: linear-gradient(transparent, #030303c2, #333);
          display: flex;
          align-items: center;
          padding: 10px;

          .icon {
            width: 45px;
            height: 45px;
            overflow: hidden;
            border-radius: 8px;
            margin-right: 10px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .text {
            color: #fff;
            flex: 1;

            .name {
              // font-size: 16px;
              font-weight: bold;

              // overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: .3rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              position: relative;
              
              .name-new {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 55%;
                margin-right: 0.1rem;
              }

              .nameRemark-new {
                background: #999;
                color: #dedede;
                font-size: .2rem;
                padding: 0 0.1rem;
                background-color: rgba(153, 153, 153, 0.3);
              }

              .discount {
                position: absolute;
                top: 0;
                right: -10px;
                background-color: red;
                color: #fff;
                font-size: 10px;
                padding: 2.5px 5px;
                line-height: 1.25;
                border-bottom-left-radius: 8px;
              }

            }

            .info1 {
              font-size: 12px;
              color: #999;
            }

            .info2 {
              display: flex;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .tag {
                margin-right: 5px;
                // color: #7a7a7a;
              }

              // .tag:nth-child(1) {
              //   color: #FF5E00;
              // }

              // .tag:nth-child(2) {
              //   color: #FFA21C;
              // }

              // .tag:nth-child(3) {
              //   color: #00a7ff;
              // }

              .tag:nth-child(1) {
                // color: #FF5E00;
                color: #F5B380;
                padding-left: 0;
                background-color: rgba(153, 153, 153, 0.3);
                padding: 0 .1rem;
              }
        
              .tag:nth-child(2) {
                color: #ACD498;
                background-color: rgba(153, 153, 153, 0.3);
                padding: 0 .1rem;
              }
        
              .tag:nth-child(3) {
                color: #89ACDA;
                background-color: rgba(153, 153, 153, 0.3);
                padding: 0 .1rem;
              }
            }
          }

          .discount {
            width: 60px;
            height: 35px;
            background-size: cover;
            display: flex;
            align-items: center;
            color: white;
            padding-left: 10px;
            font-size: .26rem;
            justify-content: center;
            position: absolute;
            bottom: 20px;
            right: 0;
          }
        }

      }
    }

    .items {
      background-color: white;
      margin: 10px 15px 10px;
      border-radius: 13px;
      position: relative;
      
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .top-items {
        display: flex;
        align-items: center;
        overflow: scroll;
        padding: .05rem .13rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .menu {
          flex: 1 9 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #282828;
          font-size: .26rem;
          margin: 5px 0 5px 0;
          text-align: center;

          .menu-title {
            font-size: 14px;
            font-weight: 500;
            color: #282828;
          }

          a {
            display: flex;
            align-items: center;
            flex-direction: row;
          }

          img {
            height: 31px;
            padding-right: 7.5px;
          }
        }

        .menus {
          margin: 12px 0 20px 0;
        }

        .item {
          flex: 1 0 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #282828;
          font-size: 0.26rem;
          margin: 12px 0 20px 0;

          img {
            width: 45px;
            height: 45px;
          }
          .menu-title{
            font-size: .26rem;
            color: #333;
            margin-top: 3px;
          }
        }
      }

      .scrollbar {
        position: absolute;
        bottom: 10px;
        left: 50%;
        height: 2px;
        width: 30px;
        transform: translateX(-50%);
        background-color: #eee;
        overflow: hidden;

        .thumb {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background-color: #ffc000;
          transform: translateX(-50%);
        }
      }

    }

    .list {
      padding: 0 -15px;
      background: #f5f6f8;
      margin: 0 5px;
        .index {
      
          &-banners {
            overflow: hidden;
            position: relative;
      
            margin: 0 10px;
      
            .bg {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              // bottom: 15%;
              bottom: 75%;
              transition: all .25s;
            }
      
            .bg2 {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              transition: all .25s;
              z-index: 11;
            }
            .bg3 {
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              bottom: 0;
              transition: all .25s;
              z-index: 11;
            }
      
            .swiper-container {
              margin-top: calc(env(safe-area-inset-top) + 45px);
              overflow: visible;
              // width: 90%;
              margin-left: auto;
              margin-right: auto;
              background-size: 100%;
              z-index: 12;
              background-repeat: no-repeat;
      
              // padding: 10px;
              padding-top: 5px;
            }
      
            .swiper-slide {
              transform: scale(0.95);
              transition: all .25s;
      
              &-active {
                transform: scale(1);
              }
            }
      
            .swiper-pagination-bullets {
              width: auto;
              left: auto;
              right: 10px;
              
            }
            .swiper-pagination-bullet {
              height: 4px;
              background-color: #ccc;
            }
      
            .swiper-pagination-bullet-active {
              background-color: #ffc000;
              width: 13px;
              border-radius: 2px;
            }
      
      
            .banner {
              width: 100%;
              height: 100%;
              display: block;
              position: relative;
              // padding-top: 100%;
              background: center no-repeat;
              background-size: 100% 100%;
              border-radius: 0px;
              overflow: hidden;
      
              border-radius: 8px;
      
              padding-top: 120%;
      
              .game {
                position: absolute;
                z-index: 22;
                bottom: 0;
                left: 0;
                right: 0;
                // background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));
                // background-image: linear-gradient(rgb(254 252 252 / 10%), #000);
                // background-image: linear-gradient(transparent,#000);
                // background-image: linear-gradient(#f8cdcd12, #000);
                background-image: linear-gradient(#f0ebeb1f, #030303c2, #000);
                display: flex;
                align-items: center;
                padding: 10px;
      
                .icon {
                  width: 65px;
                  height: 65px;
                  overflow: hidden;
                  border-radius: 8px;
                  margin-right: 10px;
      
                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                }
      
                .text {
                  color: #fff;
      
                  .name {
                    // font-size: 16px;
                    font-weight: bold;
      
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: .3rem;
                    display: flex;
                    align-items: center;
      
                    .name-new {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      max-width: 55%;
                      margin-right: 0.1rem;
                    }
      
                    .nameRemark-new {
                      background: #999;
                      color: #dedede;
                      font-size: .22rem;
                      padding: 0 0.1rem;
                      background-color: rgba(153, 153, 153, 0.3);
                    }
                  }
      
                  .info1 {
                    font-size: 12px;
                    color: #999;
                    margin: .1rem 0;
                  }
      
                  .info2 {
                    display: flex;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
      
                    .tag {
                      margin-right: 5px;
                      // color: #7a7a7a;
                    }
      
                    // .tag:nth-child(1) {
                    //   color: #FF5E00;
                    // }
      
                    // .tag:nth-child(2) {
                    //   color: #FFA21C;
                    // }
      
                    // .tag:nth-child(3) {
                    //   color: #00a7ff;
                    // }
      
                    .tag:nth-child(1) {
                      // color: #FF5E00;
                      color: #F5B380;
                      padding-left: 0;
                      background-color: rgba(153, 153, 153, 0.3);
                      padding: 0 .1rem;
                    }
              
                    .tag:nth-child(2) {
                      color: #ACD498;
                      background-color: rgba(153, 153, 153, 0.3);
                      padding: 0 .1rem;
                    }
              
                    .tag:nth-child(3) {
                      color: #89ACDA;
                      background-color: rgba(153, 153, 153, 0.3);
                      padding: 0 .1rem;
                    }
                  }
                }
              }
            }
          }
      
          &-popularGame {
            .swiper-container {
              width: 95%;
              margin-left: auto;
              margin-right: auto;
              margin-top: .2rem;
              margin-bottom: .2rem;
            }
          }
      
          &-menus {
            // margin-top: 5px;
            // height: 75px;
            overflow: hidden;
            position: relative;
            z-index: 12;
      
            .container {
              margin: auto 10px;
              // padding: 10px 0 15px 0;
              padding: 10px 0 10px 0;
              white-space: nowrap;
              overflow: hidden;
              overflow-x: scroll;
              -webkit-backface-visibility: hidden;
              -webkit-overflow-scrolling: touch;
              text-align: justify;
              display: flex;
              // position: relative;
              // z-index: 12;
      
              &::-webkit-scrollbar {
                display: none;
              }
      
              .scrollbar {
                position: absolute;
                // bottom: 0;
                bottom: 3px;
                left: 50%;
                height: 2px;
                // width: 100PX;
                width: 50px;
                transform: translateX(-50%);
                background-color: #eee;
                overflow: hidden;
      
                .thumb {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  // bottom: 8px;
                  left: 0;
                  background-color: #ffc000;
                  transform: translateX(-50%);
                }
              }
            }
      
            .menu {
              width: 20%;
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
      
              a {
                width: 100%;
              }
      
              .new-couple-img {
                width: 85%;
              }
              .icon {
                // height: 30px;
                height: 60px;
                position: relative;
      
                img {
                  display: block;
                  height: 100%;
                  margin: 0 auto;
                  object-fit: cover;
                }
              }
      
              &.badge {
                .icon:before {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 8px;
                  height: 8px;
                  transform: translate(50%, 0);
                  background-color: red;
                  border-radius: 50%;
                }
              }
      
              .name {
                // font-size: 13px;
                font-size: .24rem;
                // margin-top: 7.5px;
                overflow: hidden;
                // text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                color: #282828;
              }
            }
          }
      
          &-tips {
            color: #ccc;
            display: flex;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: center;
            padding: 10px;
      
            .text {
              font-size: 13px;
              text-align: center;
            }
      
            &:before {
              content: '';
              width: 50px;
              height: 1px;
              background-color: #ccc;
              margin-right: 10px;
            }
      
            &:after {
              content: '';
              width: 50px;
              height: 1px;
              background-color: #ccc;
              margin-left: 10px;
            }
          }
      
          &-fixdiv {
            // max-width: 720px;
            // position: fixed;
            // top: 70%;
            // width: 100%;
            // z-index: 98;
      
            position: fixed;
            z-index: 98;
            bottom: 12.5%;
            right: 0;
            max-width: 720px;
            padding: .13rem .3rem;
      
            .ssc {
              // width: 15%;
              float: right;
              margin-top: 5%;
              margin-right: 2%;
              clear: both;
              width: 60px;
              height: 60px;
            }
            .djj {
              // width: 16%;
              float: right;
              margin-right: 2%;
              width: 60px;
              height: 60px;
            }
          }
      
          &-bottom-banner {
            position: fixed;
            bottom: calc(env(safe-area-inset-bottom) + 50px);
            // bottom: 50px;
            z-index: 99;
            width: 100%;
            max-width: 720px;
      
            .close {
              // position: absolute;
              // left: 0.3rem;
              // top: 25%;
              // z-index: 2;
              // margin-top: -0.15rem;
              // width: 0.2rem;
              // height: 0.2rem;
              // background-size: contain;
              // cursor: pointer;
      
              position: absolute;
              /* left: 0.3rem; */
              top: 30%;
              z-index: 2;
              margin-top: -0.15rem;
              width: .8rem;
              height: .8rem;
              background-size: contain;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: flex-start;
      
              img {
                width: .2rem;
              }
            }
          }
      
          &-recommend-top {
            padding: 10px;
            overflow: hidden;
            background: #fff;
            margin: 10px 10px 10px;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
      
            .item {
              margin-top: 12.5px;
              display: flex;
              align-items: center;
              margin-bottom: 12.5px;
      
              .tips {
                position: absolute;
                top: 2px;
                left: 0.3rem;
                background: red;
                color: #fff;
                padding: 0 0.1rem;
                font-size: 12px;
                border-radius: 5px;
              }
              .icon {
                flex-shrink: 0;
                margin-right: 0.2rem;
                width: 1.4rem;
                height: 1.4rem;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
              }
      
              .text {
                flex: 1 1;
                width: 0;
                font-size: .24rem;
                color: #999;
                line-height: 1.6;
                display: flex;
                flex-direction: column;
                position: relative;
      
                .name {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: .3rem;
                  color: #282828;
                }
              }
      
              .operate {
                color: #ffc000;
                background: #fff;
                padding: 2px .15rem;
                border-radius: 15px;
              }
            }
          }
      
      
          &-wechat-img {
            // position: absolute;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10009;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
      
            .prompt-copy-img {
              width: 65%;
              margin: 0 auto;
              margin-top: 40%;
              margin-left: 17.5%;
              // position: fixed;
              z-index: 999999;
            }
          }
      
          &-wechat-img-bg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10002;
            width: 100%;
            height: 100%;
          }
      
          &-g-ad {
            position:fixed;
            // bottom:1rem;
            bottom: 55px;
            left:50%;
            width:100%;
            max-width:720px;
            z-index:99;
            background:rgba(0,0,0,.7);
            padding:7px .3rem 5px .5rem;
            display:-webkit-flex;
            display:-moz-box;
            display:-ms-flexbox;
            display:flex;
            -webkit-justify-content:space-between;
            -moz-box-pack:justify;
            -ms-flex-pack:justify;
            justify-content:space-between;
            -webkit-align-items:center;
            -moz-box-align:center;
            -ms-flex-align:center;
            align-items:center;
            transform:translateX(-50%);
            -webkit-transform:translateX(-50%);
            -moz-transform:translateX(-50%);
            -ms-transform:translateX(-50%);
            -o-transform:translateX(-50%);
            .close {
              position:absolute;
              left:.1rem;
              top:50%;
              z-index:2;
              margin-top:-.15rem;
              width:.3rem;
              height:.3rem;
              -o-background-size:contain;
              background-size:contain;
              cursor:pointer;
            }
            .left {
              -webkit-flex:1;-moz-box-flex:1;-ms-flex:1;flex:1;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center
            }
            .icon {
              width:.9rem;
              // height:1.02rem;
              overflow:hidden;
              margin-right:.19rem;
              img {
                width:100%;
                height:100%
              }
            }
            .txt {
              font-size:.2rem;color:#fff
            }
            .name {
              font-size:.32rem;margin-bottom:2px
            }
            .btn {
              display:block;height:.62rem;line-height:.62rem;background:#ffc000;border-radius:4px;padding:0 .18rem;font-size:.26rem;color:#fff;
            }
          }
      
          &-first-charge {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
            position: fixed;
            top: 0;
            left: 50;
            z-index: 101;
            max-width: 720px;
      
            img {
              width: 75%;
              margin-left: 12.5%;
              margin-top: 50%;
            }
            @media (min-width: 1119px) {
              img {
                width: 50%;
                margin-left: 25%;
                margin-top: 40%;
              }
            }
      
            .close001 {
              width: 12%;
              z-index: 2000;
              margin: 5% 44%;
            }
          }
      
          &-first-messageAlert {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.8);
            z-index: 999;
            max-width: 720px;
            margin: 0 auto;
            .exit-site {
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              justify-items: center;
              flex-direction: column;
              height: 100%;
              .exit-main {
                background: #fff;
                width: 75%;
                text-align: center;
                padding: 20px;
                border-radius: 8px;
      
                position: relative;
      
                .exit-top-img {
                  position: absolute;
                  width: 50%;
                  left: 25%;
                  top: -20%;
                }
      
                .bar-top {
                  display: flex;
                  justify-content: flex-end;
                  img {
                    width: 20px;
                  }
                }
                .exit-title {
                  padding-bottom: 10px;
                  font-size: .36rem;
                  color: #282828;
                  padding-top: 35px;
                }
                .info {
                  // font-size: .32rem;
                  color: #282828;
                  padding-bottom: 10px;
                  font-size: .26rem;
                  text-align: left;
                }
                .exit-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 10px;
                  .item {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    font-size: 16px;
                    transition: all .25s;
                    border-radius: 30px;
                    padding: 10px;
                  }
                  .btn-about {
                    // border: 1px solid #999999;
                    // color: #999999;
                    // margin-right: 15px;
      
                    border: 1px solid #FFC000;
                    color: #fff;
                    margin-right: 15px;
                    background: #FFC000;
                  }
                  .btn-confirm {
                    background-color: #FFC000;
                    color: #fff;
                  }
                }
              }
            }
          }
      
          &-game-info-img {
            img {
              // margin-top: 25%;
              margin-top: 50%;
            }
          }
      
          &-tradesreject {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 555;
            background-color: rgba(0,0,0,0.75);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
      
            .container {
              background-color: #ffffff;
              width: 75%;
              border-radius: 8px;
              overflow: hidden;
              .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 12.5px;
                .icon {
                  img {
                    display: block;
                    height: 75px;
                  }
                }
                .title {
                  font-size: .32rem;
                  color: #000;
                }
              }
              .info {
                padding: 0 12.5px 12.5px;
                font-size: .26rem;
                color: #666;
              }
              .btns {
                border-top: 1px solid #eee;
                display: flex;
                flex: 1 1;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                border-top: 1px solid #eee;
                color: #ffc000;
              }
            }
            .close {
              width: 8%;
              z-index: 2000;
              margin: 5% 44%;
            }
          }
      
          //首页推广专题弹框
          &-spweard {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.8);
            z-index: 999;
            max-width: 720px;
            margin: 0 auto;
            .spweard-site {
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              justify-items: center;
              flex-direction: column;
              height: 100%;
              .spweard-main {
                background: #fff;
                width: 75%;
                text-align: center;
                padding: 20px;
                border-radius: 8px;
                .close {
                  text-align: right;
                  img {
                      width: 20px;
                  }
                }
                .spweard-imt-t {
                  width: 30%;
                  margin: 0 auto;
                  margin-top: -1.5rem;
                }
                .spweard-title {
                  // padding-bottom: 10px;
                  // font-size: 22px;
      
                  color: #282828;
                  padding: 10px;
                  font-size: .34rem;
                  font-weight: bold;
                }
                .info {
                  // font-size: 16px;
                  font-size: .26rem;
                  color: #282828;
                  // padding-bottom: 10px;
                  .des2 {
                      color: #ffc000;
                  }
                }
                .spweard-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 10px;
                  .item {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    font-size: 16px;
                    transition: all .25s;
                    border-radius: 30px;
                    padding: 10px;
                  }
                  .btn-about {
                    // border: 1px solid #ffc000;
                    // color: #ffc000;
                    margin-right: 15px;
      
                    background: #D4D4D4;
                    color: #fff;
                  }
                  .btn-confirm {
                    background-color: #ffc000;
                    color: #fff;
                  }
      
                  .btn-game-pop {
                    background-color: #54C5CD;
                  }
                }
              }
            }
          }
      
      
      
        }
      
        .index-datas {
          margin-top: -5px;
        }
      
        .index-data {
          padding: 0 15px 15px 15px;
          background: #fff;
          // margin: 10px;
          // margin: 0 10px 10px;
          // margin: 10px 10px 10px;
          margin: 10px 10px 10px;
          border-radius: 8px;
        }
        .youLikeGames, .startingGames, .imagejump {
          display: none;
        }
        
      
        .data-head {
          display: flex;
          align-items: center;
          padding-top: 5px;
      
          .text {
            font-size: 18px;
            font-weight: bold;
            color: #000;
            flex: 1;
            img {
              // width: 25%;
              // width: 72px;
              height: 17px;
            }
          }
      
          .more {
            color: #ccc;
            font-size: 12px;
            padding: 5px;
      
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            .left-back {
              width: 10px;
              height: 10px;
            }
          }
        }
      
        .tips {
          color: #999;
        }
      
        .todayGames {
          padding: 0 7px;
          overflow: hidden;
          .new-today {
            
            .gametitle {
              padding: 15px 0;
              font-size: 0.34rem;
              font-weight: bold;
              color: #282828;
            }
    
            dl {
              margin-bottom: 0.28rem;
    
              dt {
                margin-bottom: 0.2rem;
                font-size: 0.3rem;
                font-weight: bold;
                color: #000;
              }
            }
    
            &-item {
              margin-bottom: .3rem;
              display: flex;
              align-items: center;
              background: #fff;
              border-radius: 8px;
    
              .icon {
                position: relative;
                overflow: hidden;
                flex-shrink: 0;
                margin-right: .2rem;
                width: 1.4rem;
                height: 1.4rem;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
    
                img {
                  display: block;
                  max-width: 100%;
                  max-height: 100%;
                  border-radius: 18px;
                }
    
                .discount {
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-color: red;
                  color: #fff;
                  font-size: 10px;
                  padding: 2.5px 5px;
                  line-height: 1.25;
                  border-bottom-left-radius: 8px;
                }
    
                .iconlable {
                  position: absolute;
                  color: #fff;
                  padding: 0 .12rem;
                  font-size: 12px;
                  font-size: .22rem;
                  background-image: linear-gradient(#009CFF,#5537FF);
                  border-bottom-right-radius: 8px;
                  border-top-left-radius: 12px;
                  bottom: 0;
                  right: 0;
        
                  &.jx {
                    background-image: linear-gradient(#E70A20,#B30705);
                  }
                }
              }
    
              .subject {
                flex: 1;
                width: 0;
                // border-bottom: 1px solid #dedede;
                // padding-bottom: .2rem;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
              }
    
              .goodGame {
                display: flex;
                align-items: center;
                justify-content: center;
        
                .goodgame-icon {
                  height: 30px;
                  width: 12px;
                }
        
                .goodgame-content {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
        
                  .goodgame-title {
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 2px;
                    
                    .goodgame-name {
                      font-size: 16px;
                      font-weight: 600;
                      color: #FF3F44;
                    }
                    .goodgame-desc {
                      font-size: 10px;
                      color: #FF3F44;
                      position: relative;
                      top: 1px;
                    }
                  }
        
                  .goodgame-text {
                    font-size: 8px;
                    color: #FF3F44;
                    position: relative;
                    top: -5px;
                  }
                }
              }

              .text {
                flex: 1;
                width: 0;
                font-size: .24rem;
                // font-size: .2rem;
                color: #999;
                line-height: 1.6;
                display: flex;
                flex-direction: column;
                position: relative;
              }
    
              .name {
                font-weight: bold;
                font-size: .3rem;
                color: #282828;
                // margin-bottom: .05rem;
    
                display: flex;
                align-items: center;
    
                .named {
                  overflow: hidden;
                  // text-overflow: ellipsis;
                  // white-space: nowrap;
    
                  span {
                    padding: 0 0.1rem;
                    color: #956528;
                    background: #EEBE75;
                    font-size: .24rem;
                    margin-right: 2px;
                    border-radius: 3px;
                    background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                  }
                }
    
                .name-new {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  // max-width: 55%;
                  margin-right: .1rem;
                }
        
                .nameRemark {
                  border-radius: 3px;
                  font-size: 12px;
                  // margin-right: 5px;
                  // margin-left: 5px;
                  display: inline;
                  // background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                  color: #946328;
                  padding-left: 0.1rem;
                  padding-right: 0.1rem;
                  height: 17px;
                  font-weight: unset;
                  vertical-align: text-bottom;
                  border: 1px solid #E3B579;
                  line-height: 1.34;
                  // margin-top: 0.06rem;
                }
    
                .nameRemark-new {
                  background: #f3f4f8;
                  color: #999;
                  font-size: .22rem;
                  padding: 0 0.1rem;
                  font-weight: normal;
                  flex-shrink: 0;
                }
    
                .time {
                  padding: 0 2.5px;
                  display: flex;
                  align-items: center;
                  font-size: 11px;
                  color: #999999;
                  font-weight: normal;
                  flex-shrink: 0;
                  background-color: #F6F6F6;
                  border-radius: 2px;
                }
              }
    
              .info1 {
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // margin-bottom: .05rem;
                // margin-bottom: .065rem;
                // margin-top: .065rem;
    
                display: flex;
                align-items: center;
                position: relative;
    
                .nameRemark {
                  color: #ED5335;
                  font-size: .2rem;
                }
    
                .time {
                  padding: 0 2px;
                  display: flex;
                  align-items: center;
                  font-size: 11px;
                  margin-left: 5px;
                  // position: absolute;
                  // right: 0;
    
                  span {
                    color: #fff;
                    background-color: deeppink;
                    border-radius: 4px;
                    padding: 0 2px;
                  }
    
                  i {
                    color: deeppink;
                    padding: 0 2px;
                  }
                }
              }
    
              .info2 {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: .24rem;
                // font-size: .2rem;
                // font-size: .22rem;
                // color: #FF5E00;
                color: #666;
                margin-bottom: .05rem;
              }
    
              .info3 {
                // margin-top: 0.065rem;
                border-radius: 3px;
    
                .first-text {
                  padding: 1px 3px;
                  color: #fff;
                  border-radius: 3px;
                }
                .second-text {
                  border: 1px solid;
                  padding: 0 0.1rem;
                  border-left: 0;
                  border-top-right-radius: 3px;
                  border-bottom-right-radius: 3px;
                  margin-left: -1px;
                }
              }
    
              .tag {
                display: inline-block;
                padding-left: 5px;
                color: #FAB72B;
              }
    
              .discount {
                position: absolute;
                // top: 0;
                top: -10px;
                right: -10px;
                color: #fff;
                font-size: 10px;
                padding: 5px 0px;
                line-height: 1.25;
                width: 50px;
                text-align: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                span {
                  font-size: 14px;
                }
              }
    
              .tag:nth-child(1) {
                // color: #FF4200;
                color: #F5B380;
                padding-left: 0;
              }
        
              .tag:nth-child(2) {
                // color: #75D100;
                color: #ACD498;
              }
        
              .tag:nth-child(3) {
                // color: #2893FF;
                color: #89ACDA;
              }
    
              .btn {
                width: 1.1rem;
                border-radius: .25rem;
                background: #ffc000;
                font-size: .28rem;
                color: #fff;
                line-height: .5rem;
                text-align: center;
              }
            }

            &-item{
              .subject {
                .name {
                  font-size: .32rem;
                }
                .info1 {
                  font-size: .24rem;
                  padding: 2px 0;
                }
              
                .info3 {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
              
                  span {
                    font-size: .24rem;
                  }
              
                  .first-text {
                    font-size: .2rem;
                  }
              
                  .second-text {
                    margin-right: 5px;
                    font-size: .2rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
              
                  .speed {
                    margin-right: 5px;
                    padding: 0 4px;
                    background-color: #FCF8ED;
                    font-size: 10px;
                    color: #D79863;
                    border-radius: 1px;
                    flex-shrink: 0;
                  }
              
                }
          
                .discount {
                  background-image: none;
                  color: #FF3F44;
                  top: calc((100% - 20px) / 2);
                  font-size: 11px;
                  width: auto;
                  padding: 0;
                  padding-right: 5px;
              
                  span {
                    font-size: 17px;
                    font-weight: 600;
                    position: relative;
                    top: 1px;
                  }
                }
              }
          
              .icon {
                height: 78px;
                width: 78px;
              }
            }
    
          }
    
        }

        .recommendGames {
          .items {
            margin-left: -10px;
          }
      
          .item {
            padding-left: 10px;
            padding-top: 10px;
            float: left;
            width: 25%;
            text-align: center;
      
            .icon {
              padding: 2.5px;
              width: 1.4rem;
              margin: 0 auto;
      
              img {
                display: block;
                width: 100%;
              }
            }
            @media (min-width: 1119px) {
              .icon {
                width: 1.4rem;
                height: 1.4rem;
                margin: 0 auto;
              }
            }
      
            .name {
              font-size: .26rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 2.5px;
              // color: #333;
              font-family: PingFang SC;
              font-weight: 500;
              color: #282828;
              font-weight: bold;
            }
      
            .info {
              color: #999;
              // font-size: 13px;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 1px;
            }
      
            .nameRemark {
              border-radius: 3px;
              font-size: 12px;
              // display: inline;
              color: #946328;
              font-weight: 500;
              // border: 1px solid #E3B579;
              display: flex;
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              justify-content: center;
              margin-top: 5px;
              font-weight: bold;
              span {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                border: 1px solid #E3B579;
                border-radius: 3px;
      
                
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
      
                line-height: 1.34;
                height: 17px;
      
                padding: 0 .05rem;
              }
            }
          }
        }
      
        .hotGames {
      
          .swiper-container {
            padding-bottom: 20px;
            overflow: visible;
          }
      
          .swiper-pagination {
            bottom: 0;
          }
      
          .swiper-pagination-bullet {
            border-radius: 4px;
          }
      
          .swiper-pagination-bullet-active {
            width: 20px;
            background-color: #fe8900;
          }
      
          .item {
            // padding-right: 15px;
            // margin-top: 12.5px;
            // display: flex;
            // align-items: center;
      
            .entity {
              display: flex;
              align-items: center;
              // margin-top: 12.5px;
              margin-top: 15px;
            }
      
            .entity-img {
              margin-top: 0.2rem;
      
              img {
                border-radius: 15px;
              }
            }
      
            .index {
              width: 25px;
              display: flex;
              align-content: center;
              align-items: center;
              justify-content: center;
              justify-items: center;
              margin-right: 5px;
            }
      
            .icon {
              flex-shrink: 0;
              margin-right: .2rem;
              width: 1.6rem;
              height: 1.6rem;
              display: flex;
              align-content: center;
              align-items: center;
              justify-content: center;
              justify-items: center;
              position: relative;
      
              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
              }
      
              .iconlable {
                position: absolute;
                color: #fff;
                padding: 0 .12rem;
                font-size: 12px;
                font-size: .22rem;
                background-image: linear-gradient(#009CFF,#5537FF);
                border-bottom-right-radius: 8px;
                border-top-left-radius: 12px;
                bottom: 0;
                right: 0;
                font-weight: bold;
      
                &.jx {
                  background-image: linear-gradient(#E70A20,#B30705);
                }
              }
            }
      
            .text {
              flex: 1;
              width: 0;
              font-size: .24rem;
              // font-size: .2rem;
              color: #999;
              // line-height: 1.5;
              line-height: 1.6;
              display: flex;
              flex-direction: column;
              // border-bottom: 1px solid #eee;
              // padding-bottom: 10px;
              position: relative;
            }
      
            .name {
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // font-size: .3rem;
              font-size: .3rem;
              color: #282828;
              // line-height: 1.2;
              // padding-bottom: 0.065rem;
              display: flex;
              align-items: center;
      
      
              // display: flex;
              // font-size: .3rem;
              // font-weight: bold;
              // color: #000;
              // align-items: center;
      
      
              span {
                padding: 0 0.1rem;
                color: #956528;
                background: #EEBE75;
                font-size: .24rem;
                margin-right: 2px;
                border-radius: 3px;
                background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                display: inline-block;
                // verticle-align: middle;
                height: 18px;
                // vertical-align: initial;
                vertical-align: top;
                margin-top: 3.3px;
              }
      
              .span {
                border-radius: 3px;
                // padding: 0px 3px;
                font-size: 12px;
                margin-right: 5px;
                display: inline;
                // padding-top: 2px;
                background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                color: #956528;
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                height: 17px;
                // padding-top: 0.5px;
                // padding-top: 1px;
                // display: inline-table;
                font-weight: unset;
                vertical-align: text-bottom;
                // vertical-align: middle;
              }
      
              .name-new {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 55%;
                margin-right: .1rem;
              }
      
              .nameRemark {
                border-radius: 3px;
                font-size: 12px;
                // margin-right: 5px;
                // margin-left: 5px;
                display: inline;
                // background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                color: #946328;
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                height: 17px;
                font-weight: unset;
                vertical-align: text-bottom;
                border: 1px solid #E3B579;
                line-height: 1.34;
                // margin-top: 0.06rem;
              }
      
              .nameRemark-new {
                background: #f3f4f8;
                color: #999;
                font-size: .22rem;
                padding: 0 0.1rem;
                font-weight: normal;
              }
            }
      
            .info1 {
              // color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // margin-bottom: .05rem;
              color: #999;
              // margin-bottom: .065rem;
              // margin-top: .065rem;
      
              .nameRemark {
                color: #ED5335;
                font-size: .26rem;
              }
            }
      
            .info2 {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: .24rem;
              // font-size: .22rem;
              color: #666;
            }
            .info3 {
              margin-top: 0.065rem;
              border-radius: 3px;
              font-size: .22rem;
              .first-text {
                padding: 1px 3px;
                color: #fff;
                border-radius: 3px;
              }
              .second-text {
                border: 1px solid;
                padding: 0 0.1rem;
                border-left: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                margin-left: -2px;
              }
            }
            .discount { 
              position: absolute;
              top: 0;
              // right: -45px;
              right: 0;
              color: #fff;
              font-size: 10px;
              padding: 5px 5px;
              line-height: 1.25;
              width: 50px;
              text-align: center;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              span {
                font-size: 15px;
              }
            }
      
            .tag {
              display: inline-block;
              padding-left: 5px;
              // color: #FAB72B;
            }
      
            .tag:nth-child(1) {
              // color: #FF4200;
              color: #F5B380;
              padding-left: 0;
            }
      
            .tag:nth-child(2) {
              // color: #75D100;
              color: #ACD498;
            }
      
            .tag:nth-child(3) {
              // color: #2893FF;
              color: #89ACDA;
            }
      
            .btn {
              width: 1.1rem;
              border-radius: .25rem;
              background: #ffc000;
              font-size: .28rem;
              color: #fff;
              line-height: .5rem;
              text-align: center;
            }
          }
        }
      
        .projectGames {
          .gametitle {
            font-size: 0.32rem;
            font-weight: 500;
            color: #282828;
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 3.5px 10px 3.5px 12px;
            color: white;
            position: relative;
            left: -20px;
          }
          .items {
            margin-left: -10px;
            .item {
              padding-left: 10px;
              padding-top: 10px;
              float: left;
              width: 25%;
              text-align: center;
              .icon {
                padding: 2.5px;
              }
              .name {
                font-size: .26rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 2.5px;
                // color: #333;
                font-family: PingFang SC;
                font-weight: 500;
                color: #282828;
                font-weight: bold;
              }
              .info {
                color: #999;
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 1px;
              }
      
              .nameRemark {
                border-radius: 3px;
                font-size: 12px;
                // display: inline;
                color: #946328;
                font-weight: 500;
                // border: 1px solid #E3B579;
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                justify-content: center;
                margin-top: 5px;
                font-weight: bold;
                span {
                  // display: flex;
                  // align-items: center;
                  // justify-content: center;
                  border: 1px solid #E3B579;
                  border-radius: 3px;
      
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
      
                  line-height: 1.34;
                  height: 17px;
      
                  padding: 0 .05rem;
                }
              }
            }
          }
          
          .project-item-third {
            display: block;
            margin-top: 14px;
            // background-color: #fff;
            // padding: 0 10px;
            border-radius: 8px;
            margin-bottom: 5px;
            .item-base {
              display: flex;
              align-items: center;
              .icon {
                flex-shrink: 0;
                margin-right: .2rem;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
              }
              .text {
                flex: 1 1;
                font-size: .24rem;
                color: #999;
                line-height: 1.5;
                display: flex;
                flex-direction: column;
                position: relative;
                width: 0;
                align-items: flex-start;
                // padding-bottom: 7.5px;
                .name {
                  font-weight: bold;
                  // overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: .34rem;
                  color: #282828;
                  margin-bottom: .05rem;
                  display: flex;
                  align-items: center;
                  max-width: 80%;
                  .named {
                    overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
      
                    span {
                      padding: 0 0.1rem;
                      color: #956528;
                      background: #EEBE75;
                      font-size: .24rem;
                      margin-right: 2px;
                      border-radius: 3px;
                      background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                    }
                  }
      
                  .name-new {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-right: .1rem;
                  }
          
                  .nameRemark {
                    border-radius: 3px;
                    font-size: 12px;
                    // margin-right: 5px;
                    // margin-left: 5px;
                    display: inline;
                    // background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                    color: #946328;
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                    height: 17px;
                    font-weight: unset;
                    vertical-align: text-bottom;
                    border: 1px solid #E3B579;
                    line-height: 1.34;
                    // margin-top: 0.06rem;
                  }
      
                  .nameRemark-new {
                    background: #f3f4f8;
                    color: #999;
                    font-size: .24rem;
                    padding: 0 0.1rem;
                    font-weight: normal;
                  }
      
                  .time {
                    padding: 0 2.5px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
      
                    span {
                      color: #fff;
                      // background-color: deeppink;
                      background-color: #F74C6C;
                      border-radius: 4px;
                      padding: 0 2.5px;
                    }
      
                    i {
                      color: deeppink;
                      padding: 0 2.5px;
                    }
                  }

                }
                .info1 {
                  color: #999;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-bottom: .05rem;
                  font-size: .26rem;
                }
                .info2 {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: .26rem;
                  color: #FAB72B;
                  max-width: 100%;
                  .tag {
                    display: inline-block;
                    padding-left: 5px;
                  }
                  .tag:nth-child(1) {
                    color: #FF4200;
                    padding-left: 0;
                  }
        
                  .tag:nth-child(2) {
                    color: #75D100;
                  }
        
                  .tag:nth-child(3) {
                    color: #2893FF;
                  }
                }
                .info-activity {
                  padding: 2px 5px;
                  border-radius: 3px;
                  font-size: 11px;
                  color: white;
                  margin-top: 5px;
                  flex: 1;
                  display: flex;
                  align-items: center;
                  background-image: linear-gradient(to right,#FB775E,#FF505B);
                
                  & img:first-child {
                    width: 10px;
                    height: 10px;
                    margin-right: 3px;
                  }
                  & img:last-child {
                    margin-left: 6px;
                    width: 3px;
                    height: 6px;
                  }
                }
                .discount {
                  position: absolute;
                  right: 0;
                  top: 10%;
                  margin-right: -14px;
                  color: #fff;
                  font-size: 10px;
                  padding: 5px 5px;
                  line-height: 1.25;
                  width: 50px;
                  text-align: center;
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                  flex-shrink: 0;
                  span {
                    font-size: 15px;
                  }
                }
              }
            }

            .item-base{
              .text {
                .name {
                  font-size: .32rem;
                }
                .info1 {
                  font-size: .24rem;
                  padding: 2px 0;
                }
              
                .info3 {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
              
                  span {
                    font-size: .24rem;
                  }
              
                  .first-text {
                    font-size: .2rem;
                    padding: 1px 3px;
                    color: #fff;
                    border-radius: 3px;
                  }
              
                  .second-text {
                    margin-right: 5px;
                    font-size: .2rem;
                    border: 1px solid;
                    padding: 0 .1rem;
                    border-left: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    margin-left: -1px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
              
                  .speed {
                    margin-right: 5px;
                    padding: 0 4px;
                    background-color: #FCF8ED;
                    font-size: 10px;
                    color: #D79863;
                    border-radius: 1px;
                    flex-shrink: 0;
                  }
              
                }
          
                .discount {
                  background-image: none;
                  color: #FF3F44;
                  font-size: 11px;
                  width: auto;
              
                  span {
                    font-size: 17px;
                    font-weight: 600;
                    position: relative;
                    top: 1px;
                  }
                }
              }

              .goodGame {
                display: flex;
                align-items: center;
                justify-content: center;
        
                .goodgame-icon {
                  height: 30px;
                  width: 12px;
                }
        
                .goodgame-content {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
        
                  .goodgame-title {
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 2px;
                    
                    .goodgame-name {
                      font-size: 16px;
                      font-weight: 600;
                      color: #FF3F44;
                    }
                    .goodgame-desc {
                      font-size: 10px;
                      color: #FF3F44;
                      position: relative;
                      top: 1px;
                    }
                  }
        
                  .goodgame-text {
                    font-size: 8px;
                    color: #FF3F44;
                    position: relative;
                    top: -5px;
                  }
                }
              }
          
              .icon {
                height: 78px;
                width: 78px;
              }
            }
    

            .item-img {
              margin-top: 7.5px;
              margin-bottom: 7.5px;
            }
            .item-desc {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #666666;
              font-size: .28rem;
            }
          }
      
        }
      
        .agame {
          padding: 0;
      
          .item {
            .entity-dividing {
              padding: 0 10px 10px;
              background-color: #261E1C;
              margin-top: -.1rem;
            }
            .entity {
              display: flex;
              align-items: center;
              // margin-top: 12.5px;
              // padding-top: 5px;
              padding: 5px 0;
            }
      
            .entity-img {
              // margin-top: 0.2rem;
              position: relative;
      
              img {
                // border-radius: 15px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
              }
      
              .img-tips {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding-left: 10px;
                padding-bottom: 10px;
                background-image: linear-gradient(#f0ebeb1f, #261E1C, #261E1C);
                color: #fff;
                font-size: .32rem;
              }
            }
      
            .index {
              width: 25px;
              display: flex;
              align-content: center;
              align-items: center;
              justify-content: center;
              justify-items: center;
              margin-right: 5px;
            }
      
            .icon {
              flex-shrink: 0;
              margin-right: .2rem;
              width: 1.4rem;
              height: 1.4rem;
              display: flex;
              align-content: center;
              align-items: center;
              justify-content: center;
              justify-items: center;
              position: relative;
      
              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
              }
      
              .iconlable {
                position: absolute;
                color: #fff;
                padding: 0 .12rem;
                font-size: 12px;
                font-size: .22rem;
                background-image: linear-gradient(#009CFF,#5537FF);
                border-bottom-right-radius: 8px;
                border-top-left-radius: 12px;
                bottom: 0;
                right: 0;
      
                &.jx {
                  background-image: linear-gradient(#E70A20,#B30705);
                }
              }
            }
      
            .text {
              flex: 1;
              width: 0;
              font-size: .24rem;
              // font-size: .2rem;
              color: #999;
              // line-height: 1.5;
              line-height: 1.6;
              display: flex;
              flex-direction: column;
              // border-bottom: 1px solid #eee;
              // padding-bottom: 10px;
              position: relative;
            }
      
            .name {
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: .3rem;
              color: #fff;
              display: flex;
              align-items: center;
      
              span {
                padding: 0 0.1rem;
                color: #956528;
                background: #EEBE75;
                font-size: .24rem;
                margin-right: 2px;
                border-radius: 3px;
                background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                display: inline-block;
                height: 18px;
                vertical-align: top;
                margin-top: 3.3px;
              }
      
              .span {
                border-radius: 3px;
                // padding: 0px 3px;
                font-size: 12px;
                margin-right: 5px;
                display: inline;
                // padding-top: 2px;
                background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                color: #956528;
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                height: 17px;
                // padding-top: 0.5px;
                // padding-top: 1px;
                // display: inline-table;
                font-weight: unset;
                vertical-align: text-bottom;
                // vertical-align: middle;
              }
      
              .name-new {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 55%;
                margin-right: .1rem;
              }
      
              .nameRemark {
                border-radius: 3px;
                font-size: 12px;
                // margin-right: 5px;
                // margin-left: 5px;
                display: inline;
                // background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                color: #946328;
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                height: 17px;
                font-weight: unset;
                vertical-align: text-bottom;
                border: 1px solid #E3B579;
                line-height: 1.34;
                // margin-top: 0.06rem;
              }
      
              .nameRemark-new {
                // background: #f3f4f8;
                // color: #999;
                color: #fff;
                font-size: .22rem;
                padding: 0 0.1rem;
                background: rgba(153,153,153,0.3);
              }
            }
      
            .info1 {
              // color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // margin-bottom: .05rem;
              color: #999;
              // margin-bottom: .065rem;
              // margin-top: .065rem;
      
              .nameRemark {
                color: #ED5335;
                font-size: .26rem;
              }
            }
      
            .info2 {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: .24rem;
              // font-size: .22rem;
              color: #666;
            }
            .info3 {
              margin-top: 0.065rem;
              border-radius: 3px;
              font-size: .22rem;
              .first-text {
                padding: 1px 3px;
                color: #fff;
                border-radius: 3px;
              }
              .second-text {
                border: 1px solid;
                padding: 0 0.1rem;
                border-left: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                margin-left: -2px;
              }
            }
            .discount { 
              position: absolute;
              top: 0;
              // right: -45px;
              right: 0;
              color: #fff;
              font-size: 10px;
              padding: 5px 5px;
              line-height: 1.25;
              width: 50px;
              text-align: center;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              span {
                font-size: 15px;
              }
            }
      
            .tag {
              display: inline-block;
              padding-left: 5px;
              background: rgba(153,153,153,0.3);
              padding: 0 0.1rem;
              margin-right: 5px;
            }
      
            .tag:nth-child(1) {
              color: #F5B380;
              // padding-left: 0;
            }
      
            .tag:nth-child(2) {
              color: #ACD498;
            }
      
            .tag:nth-child(3) {
              color: #89ACDA;
            }
      
            .btn {
              width: 1.1rem;
              border-radius: .25rem;
              background: #ffc000;
              font-size: .28rem;
              color: #fff;
              line-height: .5rem;
              text-align: center;
            }
          }
        }
      
        .imagejump {
          .items {
            display: flex;
            align-items: stretch;
          }
      
          .item {
            &:first-child {
              padding-right: 7.5px;
            }
      
            &:last-child {
              padding-left: 7.5px;
            }
      
            img {
              display: block;
              width: 100%;
            }
          }
        }
      
        .projectGames {
      
          img {
            display: block;
            // width: 100%;
            // border-radius: 12px;
            border-radius: 18px;
          }
          .project-img-four {
            margin-top: 10px;
            border-radius: 12px;
          }
        }
      
        .startingGames {
          .items {
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            overflow-x: scroll;
            -webkit-backface-visibility: hidden;
            -webkit-overflow-scrolling: touch;
            text-align: justify;
            display: flex;
            margin-left: -10px;
          }
      
          .item {
            flex-shrink: 0;
            width: 33.333%;
            padding-left: 10px;
            position: relative;
      
            .container {
              position: relative;
              border-radius: 8px;
              overflow: hidden;
            }
      
            .img {
              overflow: hidden;
              padding-top: 135%;
      
              img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                width: 100%;
                height: 100%;
              }
            }
      
            .time {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              text-align: right;
              color: #fff;
              padding: 5px;
              font-size: 13px;
              background-image: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, 0));
              padding-bottom: 20px;
            }
      
            .info {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              text-align: left;
              color: #fff;
              padding: 5px;
              padding-top: 20px;
              background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
            }
      
            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
      
            .desc {
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      
        .specialBanner {
          .items {
            margin-left: -15px;
          }
      
          .item {
            display: block;
            width: 50%;
            float: left;
            padding-left: 15px;
      
            img {
              display: block;
              width: 100%;
              border-radius: 12px;
            }
          }
        }
      
        .reserverGames {
      
          .data-body {
            margin-top: 10px;
            width: 92.5%;
            // margin-left: auto;
            margin-right: auto;
          }
      
          .swiper-container {
            overflow: visible;
          }
      
          .swiper-slide {
            padding-right: 10px;
          }
      
          .item {
            // border: 1px solid #eee;
            border-radius: 8px;
            display: block;
      
            .img {
              padding-top: 50%;
              background: center no-repeat;
              background-size: contain;
              border-radius: 12px;
            }
      
            .container {
              display: flex;
              align-items: center;
              padding: 10px 3px;
      
              .icon {
                width: 50px;
                height: 50px;
      
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
      
              .text {
                flex: 1;
                width: 0;
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-items: center;
                align-content: center;
                justify-content: center;
              }
      
              .name {
                color: #282828;
                font-size: .3rem;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                align-items: center;
                width: 100%;
      
                .name-new {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 50%;
                  margin-right: .1rem;
                }
        
                .nameRemark {
                  border-radius: 3px;
                  font-size: 12px;
                  // margin-right: 5px;
                  // margin-left: 5px;
                  display: inline;
                  // background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
                  color: #946328;
                  padding-left: 0.1rem;
                  padding-right: 0.1rem;
                  height: 17px;
                  font-weight: bold;
                  vertical-align: text-bottom;
                  border: 1px solid #E3B579;
                  line-height: 1.3;
                  // margin-top: 0.06rem;
                }
      
                .nameRemark-new {
                  background: #f3f4f8;
                  color: #999;
                  font-size: .22rem;
                  padding: 0 0.1rem;
                  font-weight: normal;
                }
              }
      
              .info1 {
                width: 100%;
      
                .nameRemark {
                  color: #ED5335;
                  font-size: .26rem;
                }
              }
      
              .time {
                // color: #ffc000;
                font-size: 13px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #FF5E00;
              }
      
              .btn {
                width: 1.1rem;
                // border-radius: .25rem;
                border-radius: .1rem;
                background: #ffc000;
                font-size: .28rem;
                color: #fff;
                line-height: .5rem;
                text-align: center;
              }
            }
          }
        }
      
        .diycategory {

          .item {
            padding: 10px;
            float: left;
            width: 33.333%;
            overflow: hidden;
            text-align: center;
            // display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
      
            &.clear {
              clear: both;
            }
      
            .icon {
              // width: 90%;
              position: relative;
              // padding-top: 90%;
              border-radius: 8px;
              width: 75%;
              padding-top:75%;
              margin: 0 auto;
      
              img {
                display: block;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
              }
      
              .badge {
                position: absolute;
                z-index: 22;
                background-color: #F51919;
                top: 0;
                right: 0;
                // padding: 1.25px;
                line-height: 1.15;
                font-size: 10px;
                color: #fff;
                transform: translate(12.5%, -25%);
                border-radius: 2px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
      
                padding: 0 3px;
                line-height: 1.3;
                border-radius: 30px;
              }
      
              .type_label {
                position: absolute;
                top: 0;
                right: 0;
                background-color: red;
                padding: 0px 5px;
                font-size: 12px;
                color: #fff;
                line-height: 1.15;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 8px;
              }
      
              .discount {
                position: absolute;
                top: 0;
                right: 0;
                color: #fff;
                font-size: 10px;
                /* padding: 5px 5px; */
                line-height: 1.25;
                width: 35px;
                text-align: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                
              }
            }
            @media (min-width: 1119px) {
              .icon {
                width: 50%;
                padding-top: 50%;
              }
            }
      
            .name {
              width: 100%;
              font-size: .26rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 5px;
              color: #333;
              font-weight: bold;
            }
      
            .nameRemark {
              border-radius: 3px;
              font-size: 12px;
              // display: inline;
              color: #946328;
              font-weight: 500;
              // border: 1px solid #E3B579;
              display: flex;
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              justify-content: center;
              margin-top: 5px;
              font-weight: bold;
              span {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                border: 1px solid #E3B579;
                border-radius: 3px;
      
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
      
                line-height: 1.34;
                height: 17px;
      
                padding: 0 .05rem;
              }
            }
          }
        }
      
        .hotCategory {
          background: #f5f6f8;
      
          .items {
            margin-left: -10px;
          }
      
          .item {
            padding-left: 10px;
            padding-top: 10px;
            float: left;
            width: 33.33%;
      
            .container {
              // background-color: #fe8900;
              // color: #fff;
              background-color: #fff;
              color: #282828;
              font-size: .28rem;
              border-radius: 4px;
              text-align: center;
              // padding: 3.5px 4.5px;
              padding: 9.5px 5.5px;
              border-radius: 25px;
            }
          }
        }
    }
  }
}