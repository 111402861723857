.new-my-reserve {
  padding: 0.2rem 0.3rem;
  background: #f5f6f8;
  min-height: 100vh;
}
.new-my-reserve-item {
  display: block;
  margin-bottom: 0.3rem;
  overflow: hidden;
  background: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.new-my-reserve-item .subject {
  display: flex;
  align-items: center;
}
.new-my-reserve-item .icon {
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.new-my-reserve-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.new-my-reserve-item .icon .iconlable {
  position: absolute;
  color: #fff;
  padding: 0 0.12rem;
  font-size: 12px;
  font-size: 0.22rem;
  background-image: linear-gradient(#009CFF, #5537FF);
  border-bottom-right-radius: 8px;
  border-top-left-radius: 12px;
  bottom: 0;
  right: 0;
}
.new-my-reserve-item .icon .iconlable.jx {
  background-image: linear-gradient(#E70A20, #B30705);
}
.new-my-reserve-item .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.new-my-reserve-item .name {
  display: flex;
  align-items: center;
}
.new-my-reserve-item .name .named {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.3rem;
  color: #282828;
}
.new-my-reserve-item .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.new-my-reserve-item .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: bold;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.34;
}
.new-my-reserve-item .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}
.new-my-reserve-item .name .date {
  margin: 0 3.5px;
  padding: 0 3.5px;
  color: #fff;
  background-color: deeppink;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
}
.new-my-reserve-item .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.new-my-reserve-item .info1 .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.new-my-reserve-item .info1 .date {
  margin: 0 3.5px;
  padding: 0 3.5px;
  color: #fff;
  background-color: deeppink;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
}
.new-my-reserve-item .reserve {
  margin: 0 5px;
  color: #F80000;
  font-weight: bold;
}
.new-my-reserve-item .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #FF5E00;
}
.new-my-reserve-item .tag {
  display: inline-block;
  padding-left: 5px;
  color: #FAB72B;
}
.new-my-reserve-item .tag:nth-child(1) {
  color: #F5B380;
  padding-left: 0;
}
.new-my-reserve-item .tag:nth-child(2) {
  color: #ACD498;
}
.new-my-reserve-item .tag:nth-child(3) {
  color: #89ACDA;
}
.new-my-reserve-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.new-my-reserve-item .medias {
  margin-top: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  display: flex;
  margin-left: -0.2rem;
}
.new-my-reserve-item .media {
  flex-shrink: 0;
  width: 25%;
  padding-left: 0.2rem;
  position: relative;
}
.new-my-reserve-item .media:last-child {
  margin-right: 0;
}
.new-my-reserve-item .media .container {
  position: relative;
}
.new-my-reserve-item .media img {
  width: 100%;
  border-radius: 8px;
}
.new-my-reserve-item .media .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 32px;
}
