.around-index {
  padding: 10px;
  background: #f5f6f8;
  min-height: 100vh;
}
.around-index .around-amound {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px;
  color: #fff;
  font-size: 0.22rem;
}
.around-index .around-amound .title {
  font-size: 0.26rem;
}
.around-index .around-amound .amount {
  font-size: 0.5rem;
}
.around-index .around-record-tips {
  margin: 15px 0;
  display: flex;
  align-items: center;
  line-height: 2;
}
.around-index .around-record-tips .name {
  flex: 1 1;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.34rem;
  font-weight: bold;
}
.around-index .around-record-tips .record {
  text-align: right;
  font-size: 0.24rem;
}
.around-index .around-record-tips .record img {
  width: 10%;
}
.around-index .around-game-item {
  display: block;
  margin-bottom: 0.2rem;
  background-color: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.around-index .around-game-item .item-base {
  display: flex;
  align-items: center;
  position: relative;
}
.around-index .around-game-item .item-gift {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  padding: 12px 0 2px;
  border-top: 1px dashed #ededed;
  margin-top: 15px;
  font-size: 0.28rem;
}
.around-index .around-game-item .item-gift .gift-num {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.around-index .around-game-item .item-gift .gift-num .g-name {
  font-size: 0.24rem;
  color: #999;
  padding-right: 0.1rem;
}
.around-index .around-game-item .item-gift .gift-num .g-num {
  font-size: 0.36rem;
  color: #282828;
  font-weight: bold;
  padding-right: 0.1rem;
}
.around-index .around-game-item .item-gift .voucher-num {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.around-index .around-game-item .item-gift .voucher-num .v-name {
  font-size: 0.24rem;
  color: #999;
  padding-right: 0.1rem;
}
.around-index .around-game-item .item-gift .voucher-num .v-num {
  font-size: 0.36rem;
  color: #282828;
  font-weight: bold;
}
.around-index .around-game-item .index {
  width: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 5px;
}
.around-index .around-game-item .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.around-index .around-game-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.around-index .around-game-item .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.around-index .around-game-item .subject {
  flex: 1;
  width: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.around-index .around-game-item .text {
  flex: 1;
  width: 0;
  font-size: 0.2rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.around-index .around-game-item .name {
  font-weight: bold;
  font-size: 0.3rem;
  color: #282828;
  display: flex;
  align-items: center;
}
.around-index .around-game-item .name span {
  padding: 0 0.1rem;
  color: #956528;
  background: #EEBE75;
  font-size: 0.24rem;
  margin-right: 2px;
  border-radius: 3px;
  background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
}
.around-index .around-game-item .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.around-index .around-game-item .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.34;
}
.around-index .around-game-item .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  font-weight: normal;
}
.around-index .around-game-item .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
}
.around-index .around-game-item .info1 .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.around-index .around-game-item .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.22rem;
  color: #666;
}
.around-index .around-game-item .info3 {
  border-radius: 3px;
}
.around-index .around-game-item .info3 .first-text {
  padding: 1px 3px;
  color: #fff;
  border-radius: 3px;
}
.around-index .around-game-item .info3 .second-text {
  border: 1px solid;
  padding: 0 0.1rem;
  border-left: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  margin-left: -1px;
}
.around-index .around-game-item .tag {
  display: inline;
  padding-left: 5px;
}
.around-index .around-game-item .tag:nth-child(1) {
  color: #F5B380;
  padding-left: 0;
}
.around-index .around-game-item .tag:nth-child(2) {
  color: #ACD498;
}
.around-index .around-game-item .tag:nth-child(3) {
  color: #89ACDA;
}
.around-index .around-game-item .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.around-index .around-game-item .discount {
  position: absolute;
  top: 0;
  right: -10px;
  color: #fff;
  font-size: 10px;
  padding: 5px 5px;
  line-height: 1.25;
  width: 50px;
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.around-index .around-game-item .item-base .subject .name {
  font-size: 0.32rem;
}
.around-index .around-game-item .item-base .subject .info1 {
  font-size: 0.24rem;
  padding: 2px 0;
}
.around-index .around-game-item .item-base .subject .info3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.around-index .around-game-item .item-base .subject .info3 span {
  font-size: 0.24rem;
}
.around-index .around-game-item .item-base .subject .info3 .first-text {
  font-size: 0.2rem;
}
.around-index .around-game-item .item-base .subject .info3 .second-text {
  margin-right: 5px;
  font-size: 0.2rem;
}
.around-index .around-game-item .item-base .subject .info3 .speed {
  margin-right: 5px;
  padding: 0 4px;
  background-color: #FCF8ED;
  font-size: 10px;
  color: #D79863;
  border-radius: 1px;
}
.around-index .around-game-item .item-base .subject .discount {
  background-image: none;
  color: #FF3F44;
  top: calc((100% - 20px) / 2);
  font-size: 11px;
  width: auto;
  padding: 0 10px 0 0;
}
.around-index .around-game-item .item-base .subject .discount span {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  top: 1px;
}
.around-index .around-game-item .item-base .icon {
  height: 78px;
  width: 78px;
}
.around-detail {
  padding: 10px;
  background: #f5f6f8;
  min-height: 100vh;
}
.around-detail .around-amound {
  padding: 0 0 10px;
  font-size: 0.34rem;
}
.around-detail .around-amound span {
  color: #FF5E00;
}
.around-detail .title-name {
  color: #282828;
  font-size: 0.34rem;
  font-weight: bold;
}
.around-detail .game-info {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0.2rem;
  background-color: #fff;
  padding: 0.2rem;
  border-radius: 8px;
}
.around-detail .game-info .index {
  width: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 5px;
}
.around-detail .game-info .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.around-detail .game-info .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.around-detail .game-info .icon .discount {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #fff;
  font-size: 10px;
  padding: 2.5px 5px;
  line-height: 1.25;
  border-bottom-left-radius: 8px;
}
.around-detail .game-info .subject {
  flex: 1;
  width: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.around-detail .game-info .text {
  flex: 1;
  width: 0;
  font-size: 0.2rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.around-detail .game-info .name {
  font-weight: bold;
  font-size: 0.3rem;
  color: #282828;
  display: flex;
  align-items: center;
}
.around-detail .game-info .name span {
  padding: 0 0.1rem;
  color: #956528;
  background: #EEBE75;
  font-size: 0.24rem;
  margin-right: 2px;
  border-radius: 3px;
  background-image: linear-gradient(to right, #EEBE75, #FFE7BD);
}
.around-detail .game-info .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.around-detail .game-info .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #E3B579;
  line-height: 1.34;
}
.around-detail .game-info .name .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  font-weight: normal;
}
.around-detail .game-info .info1 {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.around-detail .game-info .info1 .nameRemark {
  color: #ED5335;
  font-size: 0.26rem;
}
.around-detail .game-info .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.22rem;
  color: #666;
}
.around-detail .game-info .info3 {
  border-radius: 3px;
}
.around-detail .game-info .info3 .first-text {
  padding: 1px 3px;
  color: #fff;
  border-radius: 3px;
}
.around-detail .game-info .info3 .second-text {
  border: 1px solid;
  padding: 0 0.1rem;
  border-left: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  margin-left: -1px;
}
.around-detail .game-info .tag {
  display: inline;
  padding-left: 5px;
}
.around-detail .game-info .tag:nth-child(1) {
  color: #F5B380;
  padding-left: 0;
}
.around-detail .game-info .tag:nth-child(2) {
  color: #ACD498;
}
.around-detail .game-info .tag:nth-child(3) {
  color: #89ACDA;
}
.around-detail .game-info .btn {
  width: 1.1rem;
  border-radius: 0.25rem;
  background: #ffc000;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.5rem;
  text-align: center;
}
.around-detail .game-info .discount {
  position: absolute;
  top: 0;
  right: -10px;
  color: #fff;
  font-size: 10px;
  padding: 5px 5px;
  line-height: 1.25;
  width: 50px;
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.around-detail .items .item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 0.22rem;
  color: #666;
}
.around-detail .items .item .text {
  flex: 1 1;
  width: 0;
  font-size: 0.24rem;
  color: #999;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  position: relative;
}
.around-detail .items .item .text .name {
  font-size: 0.34rem;
  color: #282828;
  font-weight: bold;
}
.around-detail .items .item .text .info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.around-detail .items .item .base {
  text-align: center;
}
.around-detail .items .item .base .operate {
  font-size: 0.25rem;
  color: #fff;
  background-color: #FFC000;
  border-radius: 25px;
  padding: 5px 20px;
  width: 1.5rem;
}
.around-detail .items .item .base .operate.copy {
  background-color: #5D8AFF;
}
.around-detail .exchange-index {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  max-width: 720px;
  margin: 0 auto;
}
.around-detail .exchange-index .exchange-site {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  height: 100%;
}
.around-detail .exchange-index .exchange-site .exchange-main {
  background: #fff;
  width: 75%;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
}
.around-detail .exchange-index .exchange-site .exchange-main .close {
  text-align: right;
}
.around-detail .exchange-index .exchange-site .exchange-main .close img {
  width: 20px;
}
.around-detail .exchange-index .exchange-site .exchange-main .exchange-title {
  padding-bottom: 10px;
  font-size: 22px;
}
.around-detail .exchange-index .exchange-site .exchange-main .info {
  font-size: 0.26rem;
  color: #282828;
  padding-bottom: 10px;
  text-align: left;
}
.around-detail .exchange-index .exchange-site .exchange-main .info .des2 {
  color: #F76924;
}
.around-detail .exchange-index .exchange-site .exchange-main .exchange-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.around-detail .exchange-index .exchange-site .exchange-main .exchange-btn .item {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 16px;
  transition: all 0.25s;
  border-radius: 30px;
  padding: 7px 10px;
}
.around-detail .exchange-index .exchange-site .exchange-main .exchange-btn .btn-about {
  border: 1px solid #ffc000;
  color: #ffc000;
  margin-left: 15px;
}
.around-detail .exchange-index .exchange-site .exchange-main .exchange-btn .btn-confirm {
  background-color: #ffc000;
  color: #fff;
}
.around-rule {
  padding: 10px;
  background: #fff;
}
.around-rule .rule-info {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0.2rem;
  background-color: #f5f6f8;
  padding: 0.2rem;
  border-radius: 8px;
}
.around-rule .rule-info .icon {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 0.2rem;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.around-rule .rule-info .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.around-rule .rule-info .subject {
  flex: 1;
  width: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
}
.around-rule .rule-info .text {
  flex: 1;
  width: 0;
  font-size: 0.24rem;
  color: #282828;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
}
.around-rule .title-name {
  color: #282828;
  font-size: 0.34rem;
  font-weight: bold;
  margin: 10px 0;
}
.around-rule .rule-list {
  font-size: 0.26rem;
  color: #282828;
}
.around-rule .rule-list p {
  padding-bottom: 5px;
}
.around-record {
  padding: 10px;
  background: #f5f6f8;
  min-height: 100vh;
}
.around-record .record-block {
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
}
.around-record .input-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.around-record .input-item .name {
  color: #000;
  font-size: 0.3rem;
  width: 150px;
  font-weight: bold;
}
.around-record .input-item .name .mandatory {
  color: red;
}
.around-record .input-item .value {
  flex: 1;
  width: 0;
  overflow: hidden;
  padding-left: 10px;
  text-align: right;
  font-size: 0.28rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.around-record .input-item .value .game_names {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.around-record .input-item .value .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.around-record .input-item .value .nameRemark-new {
  background: #f3f4f8;
  color: #999;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  max-width: max-content;
}
.around-record .around-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #999;
  margin-top: 50%;
}
.around-record .around-empty img {
  display: block;
  width: 50%;
}
