.article-detail {
  background: #f5f6f8;
  min-height: 100vh;
}
.article-detail .roof-top {
  position: sticky;
  z-index: 200;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  background-color: #f5f6f8;
  padding: 15px 15px 20px 15px;
}
.article-detail .roof-top .roof-message {
  background-size: 100% 100%;
  padding: 10px 0;
}
.article-detail .roof-top .roof-message .input-item {
  display: flex;
  font-size: 0.28rem;
  color: #ffffff;
  padding: 5px 15px;
}
.article-detail .roof-top .roof-message .input-item .info {
  max-width: 77%;
}
.article-detail .roof-top .roof-message .input-item .name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.article-detail .roof-top .roof-message .input-item .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.1rem;
}
.article-detail .roof-top .roof-message .input-item .name .nameRemark-new {
  color: #fff;
  font-size: 0.22rem;
  padding: 0 0.1rem;
  background-color: rgba(243, 244, 248, 0.3);
  font-weight: normal;
}
.article-detail .roof-top .game {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background: #fff;
  border-radius: 8px;
}
.article-detail .roof-top .game .icon {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.2rem;
  overflow: hidden;
  margin-right: 0.19rem;
}
.article-detail .roof-top .game .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.article-detail .roof-top .game .text {
  flex: 1;
  width: 0;
}
.article-detail .roof-top .game .name {
  font-size: 0.3rem;
  color: #000;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
  display: flex;
  align-items: center;
}
.article-detail .roof-top .game .name .name-new {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 0.1rem;
}
.article-detail .roof-top .game .name .nameRemark {
  border-radius: 3px;
  font-size: 12px;
  display: inline;
  color: #946328;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  height: 17px;
  font-weight: unset;
  vertical-align: text-bottom;
  border: 1px solid #e3b579;
  line-height: 1.3;
}
.article-detail .roof-top .game .info {
  font-size: 0.26rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.05rem;
}
.article-detail .roof-top .game .info .nameRemark {
  color: #ed5335;
  font-size: 0.26rem;
}
.article-detail .roof-top .game .info2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.24rem;
  color: #666;
  margin-bottom: 0.05rem;
}
.article-detail .roof-top .game .info2 .tag {
  display: inline-block;
  padding-left: 5px;
}
.article-detail .roof-top .game .info2 .tag:first-child {
  color: #ff4200;
  padding-left: 0;
}
.article-detail .roof-top .game .info2 .tag:nth-child(2) {
  color: #75d100;
}
.article-detail .roof-top .game .info2 .tag:nth-child(3) {
  color: #2893ff;
}
.article-detail .roof-top .game .info3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.article-detail .roof-top .game .info3 span {
  font-size: 0.24rem;
}
.article-detail .roof-top .game .info3 .first-text {
  font-size: 0.2rem;
  padding: 1px 3px;
  color: #fff;
  border-radius: 3px;
}
.article-detail .roof-top .game .info3 .second-text {
  margin-right: 5px;
  font-size: 0.2rem;
  border: 1px solid;
  padding: 0 0.1rem;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
}
.article-detail .roof-top .game .info3 .speed {
  margin-right: 5px;
  padding: 0 4px;
  background-color: #FCF8ED;
  font-size: 10px;
  color: #D79863;
  border-radius: 1px;
}
.article-detail .roof-top .game .discount {
  background-image: none;
  color: #FF3F44;
  top: calc((100% - 20px) / 2);
  font-size: 11px;
  width: auto;
  padding: 0 10px 0 0;
}
.article-detail .roof-top .game .discount span {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  top: 1px;
}
.article-detail .roof-top .game .btn {
  color: #fff;
  font-size: 12px;
  background-color: #ffc000;
  border-radius: 15px;
  padding: 5.5px 16.5px;
}
.article-detail .act-tit {
  font-weight: bold;
  color: #000;
  font-size: 15px;
  margin: 10px 15px;
}
.article-detail .contentItems {
  margin: 0 15px;
}
.article-detail .contentItems .contentitem {
  font-size: 0.28rem;
  color: #282828;
  background: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.article-detail .contentItems .contentitem .title {
  color: #282828;
  font-size: 0.28rem;
  font-weight: bold;
}
.article-detail .contentItems .contentitem .contenttext {
  color: #666;
  font-size: 0.26rem;
}
.article-detail .article {
  padding: 10px;
  font-size: 0.28rem;
  line-height: 1.75;
  background: #fff;
  border-radius: 8px;
  margin: 0 15px;
  margin-bottom: 10px;
}
.article-detail .article .content iframe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}
.article-detail .article-footer {
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f6f8;
  padding: 15px;
}
.article-detail .article-footer .article-operate {
  height: 40px;
  line-height: 40px;
  background-color: #ffc000;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 0.36rem;
  display: block;
}
.article-index .article-head {
  position: fixed;
  z-index: 100;
  top: calc(env(safe-area-inset-top) + 40px);
  left: 0;
  right: 0;
  background-color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.article-index .article-head .item {
  flex: 1;
  position: relative;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.article-index .article-head .item:after {
  content: "";
  display: none;
  width: 25px;
  height: 2px;
  margin-top: 2.5px;
  background-color: #ffc000;
}
.article-index .article-head .item.active {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}
.article-index .article-head .item.active:after {
  display: block;
}
.article-index .article-body {
  margin-top: 45px;
  padding: 12.5px;
  position: relative;
}
.article-index .article-body .type-item {
  display: none;
}
.article-index .article-body .type-item.active {
  display: block;
}
.article-index .article-body .type-item .item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: center no-repeat;
  background-size: 100% 100%;
  border-top: 1px solid #eee;
  padding: 10px 0;
}
.article-index .article-body .type-item .item:first-child {
  border-top: none;
  padding-top: 0;
}
.article-index .article-body .type-item .item:after {
  content: ">";
  font-size: 0.3rem;
}
.article-index .article-body .type-item .item .icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.article-index .article-body .type-item .item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.article-index .article-body .type-item .item .info {
  flex: 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.article-index .article-body .type-item .item .title {
  font-size: 0.28rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.article-index .article-body .type-item .item .time {
  font-size: 13px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
