.sign-gift {
  .sign-gift-container {
    background-color: #FAFAFA;

    .games {
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      background-color: #FAFAFA;
      position: relative;
      margin-top: -15px;
      padding: 0 15px;

      .sign-gift-title {
        font-size: 17px;
        color: #282828;
        padding: 20px 0 15px;
        font-weight: 500;
      }

      .playing {
        .game {
          background-color: white;
          border-radius: 13px;
          padding: 0 15px 15px;

          .item {
            display: flex;
            align-items: center;
            padding-top: 15px;

            .content {
              flex-grow: 1;
              padding-left: 10px;

              .name {
                font-size: 16px;
                color: #282828;
                font-weight: 500;
              }
              .desc {
                font-size: 11px;
                color: #999;
              }
            }

            .btn {
              background-color: var(--MainColor);
              color: white;
              border-radius: 14px;
              width: 55px;
              height: 28px;
              font-size: 14px;
              text-align: center;
              line-height: 28px;

              &.disable {
                background-color: #E4E4E4;
                color: #666;
                font-size: 11px;
              }
            }
          }
        }
        
      }

      .allgame {
        .tags {
          display: flex;
          flex-wrap: wrap;

          .tag {
            padding: 3px 16px;
            background-color: white;
            border-radius: 16px;
            font-size: 13px;
            color: #282828;
            margin-right: 10px;
            margin-bottom: 10px;

            &.active {
              background-color: var(--MainColor);
              color: white;
            }
          }
        }

        .list {
          .item {
            padding: 15px 10px;
            background-color: white;
            border-radius: 13px;
            margin-bottom: 10px;
            display: block;

            .game {
              display: flex;
              align-items: center;

              .icon {
                height: 75px;
              }
              
              .text {
                padding-left: 10px;
                flex-grow: 1;

                .name{
                  font-weight: bold;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 16px;
                  color: #282828;
                }

                .info1 {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: #999;
                  font-size: 11px;
                  padding: 2px 0 5px;
                }

                .info3 {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  font-size: 12px;
                  
                  .first-text {
                    font-size: 10px;
                    padding: 1px 3px;
                    color: #fff;
                    border-radius: 3px;
                  }

                  .second-text {
                    margin-right: 5px;
                    font-size: .2rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border: 1px solid;
                    padding: 0 .1rem;
                    border-left: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    margin-left: -2px;
                  }

                  .speed {
                    margin-right: 5px;
                    padding: 0 4px;
                    background-color: #FCF8ED;
                    font-size: 10px;
                    color: #D79863;
                    border-radius: 1px;
                    flex-shrink: 0;
                  }
                }
              }

              .btn {
                background-color: var(--MainColor);
                color: white;
                border-radius: 14px;
                font-size: 12px;
                width: 55px;
                height: 28px;
                text-align: center;
                line-height: 28px;
              }
            }

            .gift {
              margin-top: 10px;
              background-color: #FAFAFA;
              border-radius: 4px;
              padding: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .icon {
                height: 22px;
                padding-right: 2px;
              }

              .number {
                font-size: 13px;
                color: #F32137;
              }

              .text {
                font-size: 13px;
                color: #666;
              }
            }
          }
        }
      }
    }
  }
}