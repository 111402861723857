.recovery-mask-index {
  position: relative;
}
.recovery-mask-index .recovery-mask-container .recovery-tips {
  padding: 15px 15px 0;
}
.recovery-mask-index .recovery-mask-container .recovery-tips .title {
  font-size: 19px;
  color: #282828;
  font-weight: 500;
}
.recovery-mask-index .recovery-mask-container .recovery-tips .desc {
  font-size: 13px;
  color: #666;
  padding: 5px 0 0;
}
.recovery-mask-index .recovery-mask-container .recovery-tips .desc.active {
  color: #FF0000;
}
.recovery-mask-index .recovery-mask-container .recovery-tips .recovery-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  color: #999;
}
.recovery-mask-index .recovery-mask-container .recovery-know {
  background-color: #ffc000;
  color: white;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border-radius: 25px;
  margin: 15px 0;
}
.recovery-mask-index .recovery-mask-container .recovery-hidden {
  text-align: center;
}
.recovery-mask-index .recovery-mask-container .recovery-hidden img {
  height: 12px;
}
.recovery-mask-index .recovery-mask-container .recovery-hidden span {
  font-size: 12px;
  color: #999;
  padding: 0 3px;
}
